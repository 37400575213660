import React from "react";
import { useHistory } from "react-router-dom";

export const SubscriptionItem = ({ name, subscribers, icon, id }) => {
  const history = useHistory();
  return (
    <div className="flex shadow w-full justify-between rounded p-5 my-8">
      <div className="subscription-type flex">
        <img src={icon} alt={`${name} icon`} className=" w-20 mr-10" />
        <div className="font-bold my-auto text-lg">{name}</div>
      </div>
      <div className="subscribed">
        <div className="font-bold">{subscribers} Subscriptions</div>
        <div
          className="text-sm text-right cursor-pointer"
          style={{ color: "#B3404A" }}
          onClick={() => history.push(`/subscriptions/${id}`)}
        >
          View subscribers
        </div>
      </div>
    </div>
  );
};
