import { useHistory } from "react-router-dom";
import { ReactivateAdvert } from "./ReactivateModal";

export const HistoryItem = ({
  propertyName,
  type,
  from,
  to,
  id,
  closeReactivateModal,
  showReactivateModal,
  setSelectedId,
}) => {
  const history = useHistory();
  return (
    <>
      <div className="history_table ">
        <div className="table_head rounded w-full py-3 border p-5 flex ">
          <div
            className="w-1/5 my-auto cursor-pointer"
            onClick={() => history.push(`/ads/${id}`)}
          >
            {propertyName}
          </div>
          <div className="w-1/5 my-auto">{type || "N/A"}</div>
          <div className="w-1/5 my-auto">{new Date(from).toDateString()}</div>
          <div className="w-1/5 my-auto">{new Date(to).toDateString()}</div>
          <div
            className="w-1/5 p-3 w-min rounded-lg text-white font-bold cursor-pointer"
            style={{ background: "#29CC97" }}
            onClick={() => setSelectedId(id)}
          >
            Reactivate
          </div>
        </div>
      </div>

      <ReactivateAdvert
        showModal={showReactivateModal}
        closeModal={closeReactivateModal}
        id={id}
        from={from}
        to={to}
      />
    </>
  );
};
