import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import FullLoader from "../../components/Loaders/FullLoader";
import { getSingleAdvert, updateAdvert } from "../../utils/ApiRequests";
import imgIcon from "../../assets/images/picture-icon.svg";
import MiniLoader from "../../components/Loaders/MiniLoader";

export const SingleAdvert = () => {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [advert, setAdvert] = useState();
  const [editActive, setEditActive] = useState(false);
  const [imgFile, setImgFile] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const getAdvert = async () => {
      try {
        const { data } = await getSingleAdvert(id);
        setLoading(false);
        setAdvert(data.data);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.log(error.response);
      }
    };
    getAdvert();
  }, [id, submitting]);

  if (loading) {
    return <FullLoader />;
  }

  const handleEditSubmit = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", imgFile);

    setSubmitting(true);

    try {
      await updateAdvert(id, bodyFormData);
      toast.success("Edited successfully");
      setEditActive(false);
      setSubmitting(false);
    } catch (error) {
      console.error(error.response);
      setSubmitting(false);
      error.response && toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div
        className="back-arrow flex cursor-pointer w-min"
        onClick={() => history.goBack()}
      >
        <AiOutlineArrowLeft style={{ margin: "auto" }} />
        <div className="font-normal ml-2 my-auto">Back</div>
      </div>

      <div className="w-max my-5">
        <h1 className="text-xl font-black">Advertisement</h1>
        <AccentBar width="50%" />
      </div>

      <div className="my-10">
        <div className="flex py-3 w-full">
          <div className="w-1/4 px-3 my-auto">
            {!editActive ? (
              <>
                <div className="col-title font-black mb-8">Image</div>
                <img src={advert?.banner} alt="" />
              </>
            ) : (
              <>
                <div className="contact-file-input">
                  <label htmlFor="file-upload" className="custom-file-upload">
                    <div className="file border border-gray-200 border-dashed text-center w-full p-5 ">
                      {imgFile ? (
                        <img
                          src={URL.createObjectURL(imgFile)}
                          className="m-auto"
                          alt=""
                        />
                      ) : (
                        <>
                          <img src={imgIcon} alt="" className="m-auto py-16" />
                        </>
                      )}
                    </div>
                    <div className="text-md text-center">Change image</div>
                  </label>

                  <input
                    id="file-upload"
                    className="hidden"
                    required
                    onChange={e => {
                      const [file] = e.target.files;
                      setImgFile(file);
                    }}
                    type="file"
                    accept=".png, .jpeg, .jpg"
                  />
                </div>
              </>
            )}
          </div>
          <div className="w-1/4 px-3">
            <div className="col-title font-black mb-8">Property</div>
            <div>{advert?.property.name}</div>
          </div>
          <div className="w-1/4 px-3">
            <div className="col-title font-black mb-8">Date</div>
            <div className="font-bold">{`${new Date(
              advert?.start_date
            ).toDateString()} - ${new Date(
              advert?.end_date
            ).toDateString()}`}</div>
          </div>
          <div className="w-1/4 px-3 font-black">
            <div className="col-title mb-8">Status</div>
            <div
              className="w-1/5 px-7 py-2 w-min rounded-3xl text-white font-bold"
              style={{ background: "#29CC97", textTransform: "uppercase" }}
            >
              {advert?.status}
            </div>
          </div>
        </div>
      </div>

      <div className="my-5">
        {submitting ? (
          <MiniLoader />
        ) : (
          <>
            {editActive && (
              <div className="flex">
                <div className="mr-10">
                  <CustomButton
                    buttonText="Save"
                    onClick={() => handleEditSubmit()}
                  />
                </div>
                <CustomButton
                  buttonText="Cancel"
                  onClick={() => setEditActive(false)}
                />
              </div>
            )}
            {!editActive && (
              <CustomButton
                buttonText="Update"
                onClick={() => setEditActive(true)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
