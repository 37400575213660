import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { UserListItem } from "../../components/Users/UserListItem";
import {
  deleteInternalUser,
  getAllInternalUsers
} from "../../utils/ApiRequests";
import FullLoader from "../../components/Loaders/FullLoader";

export const InternalUsers = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState();
  const [deleting, setDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  useEffect(() => {
    const fetchInternalUsers = async () => {
      try {
        const res = await getAllInternalUsers();
        setAllUsers(res.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.response);
        setLoading(false);
      }
    };
    fetchInternalUsers();
  }, [deleting]);

  if (loading) {
    return <FullLoader />;
  }

  if (deleting) {
    return <FullLoader />;
  }

  const deleteUser = async id => {
    setDeleting(true);
    try {
      await deleteInternalUser(id);
      // console.log(res);
      setDeleting(false);
      setDeleteSuccess(true);
    } catch (error) {
      setDeleting(false);
      console.log(error.response);
    }
  };

  return (
    <div className="internal-users-wrapper">
      <div className="flex w-full justify-between">
        <div className="my-auto">
          <h1 className="text-2xl ">Internal Users</h1>
          <AccentBar width="50%" />
        </div>

        <CustomButton
          buttonText="Add New User"
          onClick={() => history.push("/internal-users/add")}
        />
      </div>
      {deleteSuccess && (
        <div
          className="p-5 my-5 rounded shadow text-lg w-full"
          style={{ background: "#bdf2b7" }}
        >
          User deleted successfully
        </div>
      )}

      <div className="users-list shadow p-5 rounded my-5">
        <div className="table-header w-full flex justify-between px-5">
          <h2 className="font-bold w-1/4 text-left">User Details</h2>
          <h2 className="font-bold w-1/4 text-center">Role</h2>
          {/* <h2 className="font-bold w-1/4 text-center">Created By</h2> */}
          <h2 className="font-bold w-1/4 text-center">Action</h2>
        </div>

        <div className="users my-3">
          {allUsers?.map(user => (
            <UserListItem
              email={user.email}
              lastname={user.last_name}
              firstname={user.first_name}
              role={user.role}
              image={user.image}
              permission={user.permission}
              deleteUser={deleteUser}
              id={user.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
