import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { Link, useHistory, useParams } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import FullLoader from "../../components/Loaders/FullLoader";
import useSWRFetch from "../../hooks/useSwrFetch";

const SingleUser = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data, loading } = useSWRFetch(`/admin/user/${id}`);

  //   console.log(data);
  return (
    <div className="p-5 font-WorkSans">
      {loading && <FullLoader />}
      <div
        className="flex items-center gap-2 text-xl cursor-pointer mb-5"
        onClick={() => history.goBack()}
      >
        <BsArrowLeft />
        Back
      </div>

      <div className="w-max">
        <h1 className="text-xl font-semibold">User Details</h1>
        <AccentBar width="90%" />
      </div>

      <div className="p-5 shadow-md mt-10">
        <div className="p-5 shadow-md">
          <h1 className="font-semibold">User Details</h1>

          <div className="grid gap-5 grid-cols-3 items-center py-5 shadow-md px-5 mt-5 mb-10">
            <div className="flex gap-5 ">
              <h1 className="font-semibold">Name: </h1>
              <h1>
                {data?.data?.first_name} {data?.data?.last_name}
              </h1>
            </div>

            <div className="flex gap-5 ">
              <h1 className="font-semibold">Email: </h1>
              <h1>{data?.data?.email} </h1>
            </div>

            <div className="flex gap-5 ">
              <h1 className="font-semibold">Contact Number: </h1>
              <h1>{data?.data?.phone}</h1>
            </div>
          </div>

          <h1 className="font-semibold mb-3">User Listed Properties</h1>

          <div className="p-5 shadow-md">
            {data?.data?.properties?.map((p, i) => (
              <Link
                className="flex gap-3 mb-5"
                key={i}
                to={`/properties/details/${p?.id}`}
              >
                <img
                  src={p?.images?.length ? p?.images[0].image_url : ""}
                  alt="House"
                  className="w-32 h-24 object-cover"
                />

                <div className="w-full space-y-2 truncate">
                  <h1 className="truncate font-bold text-sm w-64">
                    {p?.address_line_one}
                  </h1>

                  <div className="flex items-center text-base space-x-1">
                    <IoLocationSharp className="text-upRed" />

                    <h1 className="text-gray-400 truncate">{p?.city}</h1>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleUser;
