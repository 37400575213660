import React from "react"
import { Switch, Route, useHistory } from "react-router-dom"
import AuthRoutes, { AuthRoutesList } from "./AuthRoutes"
import PrivateRoutes, { PrivateRoutesList } from "./PrivateRoutes"

const RouteList = [
  {
    path: AuthRoutesList.map(r => r.path),
    exact: true,
    component: AuthRoutes
  },
  {
    path: PrivateRoutesList.map(r => r.path),
    exact: true,
    component: PrivateRoutes
  }
]

const Routes = () => {
  const history = useHistory()

  history.listen(() => {
    window.scroll(0, 0)
  })

  return (
    <Switch>
      {RouteList.map(r => (
        <Route
          path={r.path}
          key={r.path}
          component={r.component}
          exact={r.exact}
        />
      ))}
    </Switch>
  )
}

export default Routes
