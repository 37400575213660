import trash from "../../assets/images/trash-bin.svg";
import { FiEdit } from "react-icons/fi";

export const SettingsItem = ({ plan, deletePlan, editPlan }) => {
  return (
    <div className="border-gray-300 border rounded-lg p-3 my-5 flex w-full justify-between">
      <div className="item-1 font-thin w-1/4"> {plan.category} </div>
      <div className="item-1 font-thin w-1/4">{plan.time}</div>
      <div className="item-1 font-thin w-1/4">₦{plan.price}</div>
      <div className="item-1 action font-thin flex w-1/4 mx-auto">
        <div
          className="edit-action flex px-8 cursor-pointer py-2 text-white rounded-3xl"
          onClick={editPlan}
          style={{ background: "#5D1763" }}
        >
          <FiEdit style={{ margin: "auto 8px auto 0" }} /> EDIT
        </div>
        <img
          className="cursor-pointer ml-10"
          onClick={() => {
            deletePlan(plan.id);
          }}
          src={trash}
          alt=""
        />
      </div>
    </div>
  );
};
