import React, { useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import { mutate } from "swr";
import { deleteProperty } from "../../utils/ApiRequests";
import { getApiErrorMessage } from "../../utils/ApiUtils";
import FullLoader from "../Loaders/FullLoader";

const PropertyListing = ({
  address_line_one,
  city,
  price,
  createdAt,
  id,
  images,
  user,
  status,
  mutate
}) => {
  const { first_name, last_name } = user || {};
  // const {  } =
  //   property || {};
  const [loading, setLoading] = useState(false);
  // const history = useHistory();
  return (
    <div className="property-details shadow-md rounded-md p-2">
      {loading && <FullLoader />}
      <Link className="flex gap-3" to={`/properties/details/${id}`}>
        <img
          src={images?.length ? images[0].image_url : ""}
          alt="House"
          className="w-32 h-24 object-cover"
        />

        <div className="w-full space-y-2 truncate">
          <h1 className="truncate font-bold text-sm w-64">
            {address_line_one}
          </h1>

          <div className="flex items-center text-base space-x-1">
            <IoLocationSharp className="text-upRed" />

            <h1 className="text-gray-400 truncate">{city}</h1>
          </div>
        </div>
      </Link>

      <div className="flex gap-3">
        <img
          src={user?.avatar.url}
          alt="Profile"
          className="w-20 h-20 object-cover rounded-full"
        />

        <div className="space-y-2">
          <h3 className="font-semibold">
            {first_name} {last_name}
          </h3>
          <h3 className="text-sm">{new Date(createdAt).toLocaleString()}</h3>
        </div>
      </div>

      <h4 className="font-bold text-center">
        ₦{parseInt(price).toLocaleString("en-NG")}
      </h4>

      <div>
        <button
          className={`w-full text-white text-center py-1 text-sm font-semibold rounded-xl ${
            status === "active" ? "bg-green-400" : "bg-gray-400"
          }`}
        >
          {status === "active" ? "ACTIVE" : status.toUpperCase()}
        </button>
      </div>

      <div>
        <button
          className="w-full text-white text-center py-1 text-sm font-semibold rounded-xl bg-upRed"
          onClick={() => {
            setLoading(true);
            deleteProperty(id)
              .then(res => {
                toast.success("Property deactivated successfully");
                mutate();
              })
              .catch(err => {
                toast.error(getApiErrorMessage(err.response));
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          DEACTIVATE
        </button>
      </div>
    </div>
  );
};

export default PropertyListing;
