export const ErrorMessage = ({ errorMessage }) => {
  return (
    <div
      className="p-5 rounded w-full border font-bold text-lg"
      style={{ background: "#b3404a96", color: "#640000" }}
    >
      {errorMessage}
    </div>
  );
};
