import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { SubscriptionItem } from "../../components/Subscriptions/SubscriptionsItem";
import { getAllSubscription } from "../../utils/ApiRequests";
import FullLoader from "../../components/Loaders/FullLoader";

export const Subscriptions = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [allSubscriptions, setAllSubscriptions] = useState();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const res = await getAllSubscription();
        setAllSubscriptions(res.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.response);
        setLoading(false);
      }
    };
    fetchSubscriptions();
  }, []);

  if (loading) {
    return <FullLoader />;
  }
  return (
    <div className="subscription__page">
      <div className="flex justify-between my-5">
        <div className="page-header my-auto">
          <h1 className="text-xl">Subscription</h1>
          <AccentBar width="100%" />
        </div>
        <CustomButton
          buttonText="Add Subscription"
          onClick={() => history.push("/subscriptions/add")}
        />
      </div>

      <div className="my-10">
        {allSubscriptions?.map((subscription) => {
          return (
            <SubscriptionItem
              key={subscription.id}
              id={subscription.id}
              name={subscription.name}
              icon={subscription.image}
              subscribers={subscription.user_count}
            />
          );
        })}
      </div>
    </div>
  );
};
