import React from "react";
import PropertySingleBidListing from "./PropertySingleBidListing";

const PropertyBidReceived = ({ bids }) => {
  console.log(bids)
  return (
    <div className="py-5 shadow-md rounded-md">
      <div className="bid-list-table py-2 text-center font-bold border-b border-gray-300 ">
        <div></div>
        <h1 className="text-left">Bids Placed By</h1>
        <h1>Received On</h1>
        <h1>Bid Price</h1>
        <h1>Counter Bid</h1>
        <h1>Status</h1>
        {/* <h1>Action</h1> */}
      </div>

      {bids?.map((bid, i) => (
        <PropertySingleBidListing key={i} {...bid} />
      ))}
    </div>
  );
};

export default PropertyBidReceived;
