import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import backArrow from "../../assets/images/arrow-left.svg";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { Subscriber } from "../../components/Subscriptions/Subscriber";
import { getSingleSubscription } from "../../utils/ApiRequests";
import FullLoader from "../../components/Loaders/FullLoader";
import { CustomButton } from "../../components/CustomButton/CustomButton";

export const Subscribers = () => {
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const { data } = await getSingleSubscription(id);
        setLoading(false);
        setSubscription(data.data);
      } catch (error) {
        setLoading(false);
        console.log(error.response);
      }
    };
    getSubscription();
  }, [id]);

  if (loading) {
    return <FullLoader />;
  }

  return (
    <div className="my-10">
      <div className="top content">
        <div
          className="back-arrow mb-5 flex cursor-pointer w-min"
          onClick={() => history.goBack()}
        >
          <img src={backArrow} alt="" />
          <div className="font-normal ml-2 my-auto">Back</div>
        </div>

        <div className="flex justify-between my-5">
          <div className="subscription-title w-max">
            <h1 className="font-bold text-lg">{subscription?.name}</h1>
            <AccentBar width="70%" />
          </div>
          <CustomButton
            buttonText="Manage Subscription"
            onClick={() => history.push("/subscriptions/manage")}
          />
        </div>

        <div className="my-3">
          <img
            className="w-20 "
            src={subscription?.image}
            alt={`${subscription?.name} icon`}
          />
        </div>
      </div>
      <div className="main-content shadow mt-10 rounded">
        <div className="table-header flex w-full border border-b border-gray-300 py-5">
          <div className="w-1/4 pl-5 font-bold text-sm">Bids Placed By</div>
          <div className="w-1/4 text-center font-bold text-sm">Start Date</div>
          <div className="w-1/4 text-center font-bold text-sm">End date</div>
          <div className="w-1/4 text-center font-bold text-sm">Status</div>
        </div>

        {subscription?.users.map((subscriber) => {
          return (
            <Subscriber
              name={`${subscriber.first_name} ${subscriber.last_name}`}
              email={subscriber.email}
              subscription={subscriber.UserSubscription}
            />
          );
        })}
        {subscription?.users.length === 0 && (
          <div className="py-5 text-center">No subscribers </div>
        )}
      </div>
    </div>
  );
};
