import React from "react";
import PropertyDocument from "./PropertyDocument";

const PropertyItemDocument = ({ documents, mutate, propertyId }) => {
  // console.log(documents);
  return (
    <div className="pt-5 shadow-md rounded-md">
      <div className="document-table text-center font-bold border-b border-gray-300 ">
        <h1 className="text-left">Document Name</h1>
        <h1>Added on</h1>
        <h1>View Document</h1>
        <h1>Status</h1>
        <h1>Action</h1>
      </div>

      {documents?.data?.filter(item => item?.id)?.map((d, i) => (
        <PropertyDocument
          key={i}
          {...d}
          mutate={mutate}
          propertyId={propertyId}
        />
      ))}

      {/* <PropertyDocument />
      <PropertyDocument />
      <PropertyDocument />
      <PropertyDocument />
      <PropertyDocument /> */}
    </div>
  );
};

export default PropertyItemDocument;
