import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Dashboard } from "../../assets/link-icons/dash.svg";
import { ReactComponent as Bid } from "../../assets/link-icons/bid.svg";
import { ReactComponent as Building } from "../../assets/link-icons/building.svg";
import { ReactComponent as Internal } from "../../assets/link-icons/internal.svg";
import { ReactComponent as Legal } from "../../assets/link-icons/legal.svg";
import { ReactComponent as Request } from "../../assets/link-icons/request.svg";
import { ReactComponent as Settings } from "../../assets/link-icons/settings.svg";
import { ReactComponent as Speaker } from "../../assets/link-icons/speaker.svg";
// import { ReactComponent as Subs } from "../../assets/link-icons/subs.svg";
import { ReactComponent as User } from "../../assets/link-icons/user.svg";
import { BiLogOutCircle } from "react-icons/bi";

const navList = [
  {
    to: "/dashboard",
    icon: <Dashboard className="fill-current" />,
    name: "Dashboard"
  },
  {
    to: "/properties",
    icon: <Building className="fill-current" />,
    name: "Properties"
  },
  {
    to: "/requests",
    icon: <Request className="fill-current" />,
    name: "Requests"
  },
  {
    to: "/bids",
    icon: <Bid className="fill-current" />,
    name: "Bids"
  },
  {
    to: "/users",
    icon: <User className="fill-current" />,
    name: "User Management"
  },
  {
    to: "/subscriptions",
    icon: <Dashboard className="fill-current" />,
    name: "Subscriptions"
  },
  {
    to: "/ads",
    icon: <Speaker className="fill-current" />,
    name: "Advertisement"
  },
  {
    to: "/legal",
    icon: <Legal className="fill-current" />,
    name: "Legal"
  },
  {
    to: "/internal-users",
    icon: <Internal className="fill-current" />,
    name: "Internal Users"
  },
  {
    to: "/settings",
    icon: <Settings className="fill-current" />,
    name: "Settings"
  },
  {
    to: "/",
    icon: (
      <BiLogOutCircle style={{ fontSize: "26px" }} className="fill-current" />
    ),
    name: "Logout"
  }
];

const Sidebar = () => {
  // const history = useHistory();
  return (
    <div className=" lg:w-80 w-52 flex flex-col sideba bg-primary overflow-y-auto hide-scrollbar overflow-x-hidden h-screen pt-5 relative z-10">
      <img
        src={require("../../assets/images/logo-white.svg").default}
        className="w-8/12 h-16 mx-auto"
        alt="Company Logo"
      />

      <div className="flex-1 flex flex-col justify-between">

        <div className="pt-10 ml-10 space-y-5 h-4/5 ">
          {navList.map((l, i) => {
            return (
              <NavLink
                to={l.to}
                className="flex gap-5 text-lg text-center  items-center fill-current px-2   font-circular rounded cursor-pointer w-4/5 p-2.5 text-gray hover:bg-primaryDull "
                activeClassName={`    ${l.name === "Logout" ? "text-white " : "bg-white text-upBlack"
                  }   py-1 rounded-l-2x`}
                key={l.to}
                onClick={() => {
                  if (l.name === "Logout") {
                    localStorage.removeItem("UPL-ADM");
                  }
                }}
              >
                {l.icon}
                {l.name}
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;


// <div className=" w-ful flex w-80 flex-col sidebar bg-primary overflow-y-auto  overflow-x-hidden h-screen pt-5 relative z-10 ">
//   <Link
//     to="/dashboard"
//     className="cursor-pointer flex justify-center"
//   >
//     <img src={logo} alt="logo" />
//   </Link>
//   <div className="flex-1 flex flex-col justify-between">

//     <div className="pt-10 ml-10 space-y-5 h-4/5 ">
//       {userDashboard.map(({ id, image, navItem, link }) => {
//         return (
//           <NavLink
//             exact
//             activeClassName="active-dashboard "
//             to={link}
//             className="flex items-center font-circular rounded cursor-pointer w-4/5 p-2.5 text-gray hover:bg-primaryDull  "
//             key={id}
//             onClick={scrollToTop}
//           >
//             <img src={image} alt={navItem} className="mr-4" />
//             <p className="font-normal  text-sm " style={{ flex: "1" }}>
//               {navItem}
//             </p>
//           </NavLink>
//         );
//       })}
//       <p
//         onClick={handleLogout}
//         className="font-bold cursor-pointer text-white ml-10 text-base"
//       >
//         Logout
//       </p>
//     </div>


// //     <DashboardSidebarFooter />

// //   </div>
// // </div>