import React, { useState, useEffect } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
// import { Form1 } from "./Form1";
// import { PreviewAd } from "./Preview";
import backArrow from "../../assets/images/arrow-left.svg";
import { AdvertisementHistory } from "./AdvertHistory";
import { createAdvert, getPropertyList } from "../../utils/ApiRequests";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import { ActiveAdvert } from "./ActiveAdvert";

export const Advertisement = () => {
  const [imgFile] = useState();
  const [step, setStep] = useState(1);
  const [activeComponent, setActiveComponent] = useState("active");
  const [allProperties, setAllProperties] = useState();
  const [from] = useState();
  const [to] = useState();
  const [propertyId] = useState();
  const [submitting, setSubmitting] = useState(false);

console.log(typeof allProperties, typeof submitting);

  const history = useHistory();

  useEffect(() => {
    const fetchAllProperties = async () => {
      try {
        const res = await getPropertyList();
        // console.log(res.data);
        setAllProperties(res.data.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    fetchAllProperties();
  }, []);

  const submitAdvertForm = async e => {
    e.preventDefault();
    setSubmitting(true);

    let bodyFormData = new FormData();
    bodyFormData.append("property_id", parseInt(propertyId));
    bodyFormData.append("file", imgFile);
    bodyFormData.append("from", new Date(from));
    bodyFormData.append("end", new Date(to));
    try {
      await createAdvert(bodyFormData);
      // console.log(res.data);
      setSubmitting(false);
    } catch (error) {
      console.log(error.response);
      setSubmitting(false);
    }
  };

  console.log(typeof submitAdvertForm);

  return (
    <>
      {step === 2 && (
        <div
          className="back-arrow flex cursor-pointer w-min"
          onClick={() => setStep(1)}
        >
          <img src={backArrow} alt="" />
          <div className="font-normal ml-2 my-auto">Back</div>
        </div>
      )}

      <div className="edit-wrapper">
        <div className="flex justify-between my-5">
          <div className=" w-max my-5">
            <h1 className="text-xl">Advertisement</h1>
            <AccentBar width="80%" />
          </div>
          <CustomButton
            buttonText="Add New"
            onClick={() => history.push("/ads/create")}
          />
        </div>

        <div className="shadow p-5 my-10">
          <div className="mb-8 flex">
            <div
              className=" w-max my-5 mr-8 cursor-pointer"
              onClick={() => setActiveComponent("active")}
            >
              <h1 className="text-xl">Active</h1>
              {activeComponent === "active" && <AccentBar width="60%" />}
            </div>
            <div
              className="w-max my-5 cursor-pointer"
              onClick={() => setActiveComponent("history")}
            >
              <h1 className="text-xl">History</h1>
              {activeComponent === "history" && <AccentBar width="60%" />}
            </div>
          </div>

          {activeComponent === "active" && <ActiveAdvert />}
          {activeComponent === "history" && <AdvertisementHistory />}
        </div>
      </div>
    </>
  );
};
