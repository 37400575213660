import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ApiRequestWithToken } from "../../utils/ApiRequests"
import trash from "../../assets/images/trash-bin.svg";
import { toast } from "react-toastify";


export const BidSubscriptionItem = ({ name, subscribers, icon, id }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory();

  const deleteUser = async id => {
    try {
      const response = await ApiRequestWithToken().delete(`/admin/token-plan/${id}`)
      const res = await response.data
      toast(res.data)
    } catch (error) {
      toast(error.response)
      console.log(error.response);
    }
  };

  return (
    <div className="flex shadow w-full justify-between items-center rounded p-5 my-8">
      <div className="subscription-type flex">
        <img src={icon} alt={`${name} icon`} className=" w-20 mr-10" />
        <div className="font-bold my-auto text-lg">{name}</div>
      </div>
      <button
        onClick={() => deleteUser(id)}
        className="subscribed mr-10 ">
        <img
          src={trash}
          className="m-auto cursor-pointer"
          alt=""
        />

      </button>
    </div>
  );
};
