import React from "react";
import { FormInput } from "../../components/FormInput/FormInput";
import imgIcon from "../../assets/images/picture-icon.svg";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import MiniLoader from "../../components/Loaders/MiniLoader";

export const Form1 = ({
  setStep,
  imgFile,
  setImgFile,
  allProperties,
  submitAdvertForm,
  propertyId,
  setPropertyId,
  setTo,
  setFrom,
  submitting,
}) => {
  return (
    <form onSubmit={submitAdvertForm}>
      <div className="flex">
        <div className="w-1/2 pr-16">
          <label> Property</label>
          <select
            name="property"
            required
            onChange={(e) => setPropertyId(e.target.value)}
            className="border border-gray-500 rounded px-2 py-2 w-full"
          >
            <option value="">Select property</option>
            {allProperties?.map((property) => {
              return (
                <option key={property.id} value={property.id}>
                  {property.name}
                </option>
              );
            })}
          </select>
          {/* <FormInput label="Property" required={true} type="text" /> */}
        </div>
        {/* <div className="w-1/2 pr-5">
          <FormInput label="Advertisement Type" required={true} type="text" />
        </div> */}
      </div>
      <div className="flex">
        {/* <div className="w-1/2 pr-16">
          <FormInput label="Listed By" required={true} type="text" />
        </div> */}
        <div className="w-1/2 flex pr-5">
          <div className="w-1/2 pr-5">
            <FormInput
              label="From"
              onChange={(e) => setFrom(e.target.value)}
              required={true}
              type="date"
            />
          </div>
          <div className="w-1/2 ">
            <FormInput
              label="To"
              onChange={(e) => setTo(e.target.value)}
              required={true}
              type="date"
            />
          </div>
        </div>
      </div>
      <div className="flex my-5">
        <div className="image-input w-1/2 pr-16">
          <label className="block"> Banner Image/Gif/Video</label>

          <div className="contact-file-input">
            <label htmlFor="file-upload" className="custom-file-upload">
              <div className="file shadow rounded w-full p-5 ">
                {imgFile ? (
                  <img
                    src={URL.createObjectURL(imgFile)}
                    className="m-auto"
                    alt=""
                  />
                ) : (
                  <img src={imgIcon} alt="" className="m-auto py-16" />
                )}
              </div>
            </label>

            <input
              id="file-upload"
              className="hidden"
              onChange={(e) => {
                const [file] = e.target.files;
                setImgFile(file);
              }}
              type="file"
              accept=".png, .jpeg, .jpg"
            />
          </div>
        </div>

        <div className="content w-1/2">
          <label className="block">Preview</label>
          <div className="contact-file-input">
            <label className="custom-file-upload">
              <div className="file shadow rounded w-full p-5 ">
                {imgFile ? (
                  <img
                    src={URL.createObjectURL(imgFile)}
                    className="m-auto"
                    alt=""
                  />
                ) : (
                  <img src={imgIcon} alt="" className="m-auto py-16" />
                )}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="submit-btn mb-5 mt-8">
        {submitting ? (
          <MiniLoader />
        ) : (
          <CustomButton type="submit" buttonText="Submit" />
        )}
      </div>
    </form>
  );
};
