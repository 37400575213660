import React, { useState } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { FormInput } from "../../components/FormInput/FormInput";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { createRegulation } from "../../utils/ApiRequests";
import MiniLoader from "../../components/Loaders/MiniLoader";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export const AddRegulations = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const clearForm = () => {
    setContent("");
    setTitle("");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const data = {
      title: title,
      content: content
    };

    try {
      const res = await createRegulation(data);
      // console.log(res);
      setLoading(false);
      clearForm();
      toast.success(res.data.message);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      toast.error(error.response.data.data);
    }
  };
  return (
    <>
      <div
        className="back-arrow flex cursor-pointer w-min"
        onClick={() => history.goBack()}
      >
        <AiOutlineArrowLeft style={{ margin: "auto" }} />
        <div className="font-normal ml-2 my-auto">Back</div>
      </div>
      <div className="edit-wrapper">
        <div className="w-max my-5">
          <h1 className="text-xl">Add Regulations</h1>
          <AccentBar width="50%" />
        </div>

        <div className="shadow p-5 my-10">
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="w-1/2 pr-5">
                <FormInput
                  label="Regulation Title"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  required={true}
                  type="text"
                />
              </div>
            </div>
            <div className="content w-1/2">
              <label className="block">Content</label>
              <textarea
                className="bg-gray-100 p-3 w-full"
                name="content"
                required
                value={content}
                onChange={e => setContent(e.target.value)}
                placeholder="Enter content"
                rows="10"
              ></textarea>
            </div>

            <div className="submit-btn mb-5 mt-8">
              {loading ? (
                <MiniLoader />
              ) : (
                <CustomButton buttonText="Publish" type="submit" />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
