import React from "react";
import { IoLocationSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

const BidsListing = ({
  amount,
  highest,
  user,
  createdAt,
  status,
  property,
  id,
  seller,
  ...props
}) => {
  const { address_line_one, city, images } = property || {};
  const { first_name, last_name, email } = user || {};

  return (
    <tr className="bid-gri shadow-cardShadow borde bg-white rounded-md p-4  items-center">
      <td className="  p-4 ">
        {/* <img
          className="w-32 h-32 object-cover mr-3 rounded-md "
          src={property?.images?.[0]?.image_url}
          alt="property listing"
        /> */}

        <div className="w-full space-y-3 my-2">
          <h1 className="w-full font-bold break-words">{address_line_one}</h1>

          <div className="flex items-center text-base space-x-1">
            <IoLocationSharp className="text-upRed" />

            <h1 className="text-gray-400 truncate">{city}</h1>
          </div>

          <h1 className="font-bold">
            ₦{parseInt(amount).toLocaleString("en-NG")}
          </h1>
        </div>
      </td>



      <td className="">
        <div className="flex items-center" >

          {/* <img
            src={user?.avatar?.url}
            alt="Profile"
            className="w-10 h-10 object-cover rounded-full"
          /> */}

          <div className="space-y-2 break-words w-full">
            <h3 className="font-semibold ">
              {seller?.first_name} {seller?.last_name}
            </h3>
            <h3 className="text-sm break-words">{seller?.email}</h3>
          </div>
        </div>
      </td>


      {/* <td>
        <div className="flex self-center items-center" >

          <img
            src={user?.avatar?.url}
            alt="Profile"
            className="w-10 h-10 object-cover rounded-full"
          />

          <div className="space-y-2 break-words w-full">
            <h3 className="font-semibold ">
              {first_name} {last_name}
            </h3>
            <h3 className="text-sm break-wor">{email}</h3>
          </div>
        </div>

      </td> */}



      <td className="text-lg text-center">
        {new Date(createdAt).toLocaleDateString()}
      </td>

      <td className="text-center">₦{parseInt(amount).toLocaleString("en-NG")}</td>
      {/* <p className="text-center">
        ₦{parseInt(highest).toLocaleString("en-NG")}
      </p> */}

      <td>
        <div>


          <button className={`py-2 w-full text-center text-sm font-semibold text-white  rounded-3xl ${status === 'successful' ? 'bg-upGreen' : (status === 'countered' ? 'bg-red-500' : 'bg-yellow')} `}>
            {status?.toUpperCase() || "INACTIVE"}
          </button>
        </div>
      </td>
      <td>

        <Link to={`/bids/${id}`} className="w-full block">
          <img
            src={require("../../assets/images/eye.svg").default}
            alt="Eyes"
            className="cursor-pointer mx-auto"
          // onClick={() => window.}
          />
        </Link>
      </td>
    </tr >
  );
};

export default BidsListing;
