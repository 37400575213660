import React, { useState } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FormInput } from "../../components/FormInput/FormInput";
import { AddUserToggleItem } from "../../components/Users/AddUserToggle";
import { addInternalUser } from "../../utils/ApiRequests";
import MiniLoader from "../../components/Loaders/MiniLoader";

export const AddUser = () => {
  const [state, setState] = useState({
    email: "",
    name: "",
  });
  const [hasProperty, setHasProperty] = useState(false);
  const [hasLegal, setHasLegal] = useState(false);
  const [hasAdvert, setHasAdvert] = useState(false);
  const [hasUserMgt, setHasUserMgt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const clearForm = () => {
    setState({ name: "", email: "" });
    setHasAdvert(false);
    setHasLegal(false);
    setHasProperty(false);
    setHasUserMgt(false);
  };
  const clearMessage = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      ...state,
      legal: hasLegal,
      property: hasProperty,
      user: hasUserMgt,
      advertisement: hasAdvert,
    };
    try {
      const res = await addInternalUser(formData);
      clearForm();
      setLoading(false);
      // console.log(res.data);
      setSuccessMessage(res.data.data);
    } catch (error) {
      // console.log(error.response);
      setErrorMessage(error.response.data.data);
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="w-max my-5">
        <h1 className="text-xl">Add User</h1>
        <AccentBar width="50%" />
      </div>

      <div className="user-form shadow p-5">
        <form onSubmit={handleSubmit}>
          <div className="flex">
            <div className="w-1/2 mr-3 my-3">
              <FormInput
                label="Username"
                onChange={(e) => {
                  handleChange(e);
                  clearMessage();
                }}
                name="name"
                type="text"
                required={true}
              />
            </div>
            <div className="w-1/2 mr-3 my-3">
              <FormInput
                label="Email Address"
                onChange={(e) => {
                  handleChange(e);
                  clearMessage();
                }}
                name="email"
                type="email"
                required={true}
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 mr-3 my-3">
              <FormInput name="role" label="Role" type="text" required={true} />
            </div>
            <div className="w-1/2 mr-3 my-3">
              <FormInput
                name="createdBy"
                label="Created By"
                type="text"
                required={true}
              />
            </div>
          </div>

          <AddUserToggleItem
            hasProperty={hasProperty}
            setHasProperty={setHasProperty}
            name="property"
            itemName="Property Management"
          />
          <AddUserToggleItem
            name="legal"
            hasLegal={hasLegal}
            setHasLegal={setHasLegal}
            itemName="Legal Management"
          />
          <AddUserToggleItem
            name="user"
            hasUserMgt={hasUserMgt}
            setHasUserMgt={setHasUserMgt}
            itemName="User Management"
          />
          <AddUserToggleItem
            itemName="Advertisement"
            name="advert"
            setHasAdvert={setHasAdvert}
            hasAdvert={hasAdvert}
          />

          <div className="form-btn my-5">
            {loading ? (
              <MiniLoader />
            ) : (
              <CustomButton buttonText="Save" type="submit" />
            )}
          </div>
        </form>
        {successMessage !== "" && (
          <div
            className="p-5 rounded w-full border font-bold text-lg"
            style={{ background: "#b0ffb0" }}
          >
            {successMessage}
          </div>
        )}
        {errorMessage !== "" && (
          <div
            className="p-5 rounded w-full border font-bold text-lg"
            style={{ background: "#b3404a96", color: "#640000" }}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};
