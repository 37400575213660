import React from "react";

export const AccentBar = ({ width }) => {
  return (
    <div
      className={`accent-bar border border-b-4 m-auto border-gray-800`}
      style={{ width: `${width}` }}
    ></div>
  );
};
