import React, { useState } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import FullLoader from "../../components/Loaders/FullLoader";
import UserItemListing from "../../components/UsersMangamentItems/UserItemListing";
// import useFetch from "../../hooks/useFetch";
import useSWRFetch from "../../hooks/useSwrFetch";
// import { getUserListing } from "../../utils/ApiRequests";

const UsersIndex = () => {
  const [page] = useState(1);
  const { data, loading } = useSWRFetch(
    `/admin/user/list?sort=DESC&page=${page}&size=5`
  );
  console.log(data);
  return (
    <div>
      {loading && <FullLoader />}
      <div className="w-max">
        <h1 className="text-xl font-semibold">User Mangement</h1>
        <AccentBar width="60%" />
      </div>

      <div className="w-full px-5 mt-5 pt-5 pb-5 shadow-md rounded-md space-y-5">
        <div className="user-grid  p-2 text-center font-bold">
          <h1 className="text-left">User Details</h1>
          <h1>User Type</h1>
          <h1>Total Listings</h1>
          <h1>Total Purchase</h1>
          <h1>Status</h1>
        </div>

        {!loading && data?.data?.map(l => <UserItemListing {...l} />)}
      </div>
    </div>
  );
};

export default UsersIndex;
