import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FormInput } from "../../components/FormInput/FormInput";
import MiniLoader from "../../components/Loaders/MiniLoader";
import { ErrorMessage } from "../../components/Message/ErrorMessage";
import { SuccessMessage } from "../../components/Message/SuccessMessage";
import { addBoostingPlan } from "../../utils/ApiRequests";

export const AddBoostPlan = () => {
  const [category, setCategory] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const clearForm = () => {
    setPrice("");
    setDuration("");
    setCategory("");
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      category: category,
      duration: duration,
      price: price,
    };
    try {
      const res = await addBoostingPlan(data);
      setLoading(false);
      setSuccessMessage(res.data.message);
      clearForm();
    } catch (error) {
      console.error(error);
      setLoading(false);
      setErrorMessage(error.response.data.message);
    }
  };
  return (
    <>
      <div className="settings-addplanWrapper">
        <div
          className="back-arrow flex cursor-pointer w-min"
          onClick={() => history.goBack()}
        >
          <AiOutlineArrowLeft style={{ margin: "auto" }} />
          <div className="font-normal ml-2 my-auto">Back</div>
        </div>

        <div className="w-max my-5">
          <h1 className="text-xl">Add Boost Plan</h1>
          <AccentBar width="50%" />
        </div>

        <form
          className="settings-form-wrapper shadow-md p-10"
          onSubmit={submit}
        >
          <div className="flex">
            <div className="w-1/2 pr-5">
              <FormInput
                label="Category"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  setErrorMessage("");
                }}
                required={true}
                type="text"
              />
            </div>
            <div className="w-1/2 pr-5">
              <FormInput
                label="Duration"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                  setErrorMessage("");
                }}
                required={true}
                type="number"
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 pr-5">
              <FormInput
                label="Price"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                  setErrorMessage("");
                }}
                required={true}
                type="number"
              />
            </div>
          </div>

          <div className="submit-btn mb-5 mt-8">
            {loading ? (
              <MiniLoader />
            ) : (
              <CustomButton buttonText="Submit" type="submit" />
            )}
          </div>
          {successMessage !== "" && (
            <SuccessMessage successMessage={successMessage} />
          )}
          {errorMessage !== "" && <ErrorMessage errorMessage={errorMessage} />}
        </form>
      </div>
    </>
  );
};
