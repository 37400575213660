import { useHistory } from "react-router-dom";

export const LegalItem = ({ title, content, image, id }) => {
  const history = useHistory();
  return (
    <div className="flex shadow rounded p-5 my-5">
      <div className="w-1/5">
        <img src={image} className="w-full" alt="" />
      </div>
      <div className="w-4/5 ml-5">
        <div
          className="font-bold cursor-pointer mb-5"
          onClick={() => history.push(`/legal/blog/${id}`)}
        >
          {title}
        </div>
        <div className="legal-content text-gray-500 text-sm">{content}</div>
      </div>
    </div>
  );
};

export const NewsItem = ({ news }) => {
  const history = useHistory();
  return (
    <div className="flex shadow rounded p-5 my-5">
      <div className="w-1/5">
        <img src={news.image} className="w-full" alt="" />
      </div>
      <div className="w-4/5 ml-5">
        <div
          className="font-bold cursor-pointer mb-5"
          onClick={() => history.push(`/legal/news/${news.id}`)}
        >
          {news.title}
        </div>
        <div className="legal-content text-gray-500 text-sm">
          {news.content}
        </div>
      </div>
    </div>
  );
};

export const RegulationItem = ({ regulation }) => {
  const history = useHistory();
  return (
    <div className="shadow rounded p-5 my-5">
      <div
        className="font-bold cursor-pointer mb-5"
        onClick={() => history.push(`/legal/regulations/${regulation.id}`)}
      >
        {regulation.title}
      </div>
      <div className="legal-content text-gray-500 text-sm">
        {regulation.content}
      </div>
    </div>
  );
};
