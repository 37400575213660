import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import FullLoader from "../../components/Loaders/FullLoader";
import useSWRFetch from "../../hooks/useSwrFetch";
const Profile = () => {
  const { data, loading } = useSWRFetch("/auth/me");

  // console.log(data);

  return (
    <div className="w-full ">
      {loading && <FullLoader />}
      <h1 className="text-2xl font-bold page-title relative">Profile</h1>

      <div className="mt-10 flex gap-28 w-full">
        <div className="space-y-2 w-60 text-center">
          <img
            src={data?.data?.avatar?.url}
            alt="Profile"
            className="w-full object-cover rounded-full h-60"
          />
          <h1 className="text-2xl font-semibold">
            {data?.data?.first_name} {data?.data?.last_name}
          </h1>
          <h2 className="font-medium">{data?.data?.role?.name}</h2>
        </div>
        <div className="w-7/12">
          <div className="flex px-5  py-3 bg-black text-white items-center justify-between">
            <h2 className="font-bold">Personal Information</h2>
            <Link to="/profile/edit">
              {" "}
              <FaRegEdit className="" />
            </Link>
          </div>

          <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">First Name: </h3>
            <p className="truncate">{data?.data?.first_name}</p>
          </div>

          <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">Last Name: </h3>
            <p className="truncate">{data?.data?.last_name}</p>
          </div>

          {/* <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">Address: </h3>
            <p className="truncate">3, Baale Street, Makogi, Lekki</p>
          </div> */}

          {/* <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">Country: </h3>
            <p className="truncate">Nigeria</p>
          </div> */}

          {/* <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">State: </h3>
            <p className="truncate">Lagos State</p>
          </div> */}
          {/*
          <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">City: </h3>
            <p className="truncate">Lekki, Lagos</p>
          </div> */}

          <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">Contact Number: </h3>
            <p className="truncate">{data?.data?.phone}</p>
          </div>

          <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">Email Address: </h3>
            <p className="truncate">{data?.data?.email}</p>
          </div>

          <div className="py-3 px-5 w-full border border-gray-300 flex border-t-0">
            <h3 className="w-36 font-semibold">Role: </h3>
            <p className="truncate">{data?.data?.role?.name}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
