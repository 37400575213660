import { FormInput } from "../FormInput/FormInput";
import { Modal } from "../Subscriptions/EditSubscriptionModal";
import { useState, useEffect } from "react";
import MiniLoader from "../Loaders/MiniLoader";
import { CustomButton } from "../CustomButton/CustomButton";
import { reactivateAdvert } from "../../utils/ApiRequests";
import { toast } from "react-toastify";

export const ReactivateAdvert = ({ showModal, closeModal, id, from, to }) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);

  const handleReactivate = async e => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      from: new Date(startDate).toDateString(),
      end: endDate
    };
    try {
       await reactivateAdvert(id, payload);
      // console.log(res);
      toast.success("Reactived successfully");
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setStartDate(new Date(from).toISOString().split("T")[0]);
    setEndDate(new Date(to).toISOString().split("T")[0]);
  }, [from, to, id]);

  return (
    <Modal
      showModal={showModal}
      closeModal={closeModal}
      children={
        <>
          <div className="p-20">
            <div className="text-center text-2xl font-black">
              Reactivate Advert
            </div>

            <form className="my-10" onSubmit={handleReactivate}>
              <div className="flex my-5">
                <div className="w-1/2 pr-5">
                  <FormInput
                    label="Start Date"
                    value={startDate}
                    onChange={e => {
                      setStartDate(e.target.value);
                    }}
                    required={true}
                    type="date"
                  />
                </div>
                <div className="w-1/2 pr-5">
                  <FormInput
                    label="End Date"
                    value={endDate}
                    onChange={e => {
                      setEndDate(e.target.value);
                    }}
                    required={true}
                    type="date"
                  />
                </div>
              </div>

              <div className="my-5">
                {loading ? (
                  <MiniLoader />
                ) : (
                  <CustomButton buttonText="Save" type="submit" />
                )}
              </div>
            </form>
          </div>
        </>
      }
    ></Modal>
  );
};
