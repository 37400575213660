import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileInputs from "../../components/InputFields/ProfileInputs";
import FullLoader from "../../components/Loaders/FullLoader";
// import useFetch from "../../hooks/useFetch";
import useSWRFetch from "../../hooks/useSwrFetch";
import { updateProfile } from "../../utils/ApiRequests";
import { getApiErrorMessage } from "../../utils/ApiUtils";

const EditProfile = () => {
  const { data, loading } = useSWRFetch(`/auth/me`);
  const [loading2, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    contact_number: "",
    email: ""
  });
  const history = useHistory();

  useEffect(() => {
    setForm({
      name: `${data?.data?.first_name} ${data?.data?.last_name}`,
      contact_number: data?.data?.phone,
      email: data?.data?.email
    });
  }, [data]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const newEntry = { [name]: value };
    setForm({ ...form, ...newEntry });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    updateProfile(form)
      .then(() => history.push("/profile"))
      .catch(err => toast.error(getApiErrorMessage(err?.response)))
      .finally(() => setLoading(false));
  };

  return (
    <form className="w-full " onSubmit={handleSubmit}>
      {(loading || loading2) && <FullLoader />}
      <h1 className="text-2xl font-bold page-title relative">Edit Profile</h1>

      <div className="mt-10 grid grid-cols-3 w-full gap-10">
        {/* <ProfileInputs label="" name="fname" /> */}
        <ProfileInputs
          label="Name"
          name="name"
          handleChange={handleChange}
          value={form.name}
        />
        {/* <ProfileInputs label="Address" /> */}
        {/* <ProfileInputs label="Country" /> */}
        {/* <ProfileInputs label="State" /> */}
        {/* <ProfileInputs label="City" /> */}
        <ProfileInputs
          label="Contact Number"
          name="contact_number"
          handleChange={handleChange}
          value={form.contact_number}
        />
        <ProfileInputs
          label="Email Address"
          name="email"
          handleChange={handleChange}
          value={form.email}
        />
        {/* <ProfileInputs label="Website URL" /> */}
      </div>
      <div>
        <button className="w-64 my-5 font-semibold bg-black rounded-md py-3 text-white text-center">
          Save
        </button>
      </div>
    </form>
  );
};

export default EditProfile;
