import React from "react";
import DashRecentListing from "../../components/DashboardItems/DashRecentListing";
import FullLoader from "../../components/Loaders/FullLoader";
// import useFetch from "../../hooks/useFetch";
// import {
//   getDashboardGraph,
//   getDashboardStats,
//   getDashborardRecent
// } from "../../utils/ApiRequests";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import useSWRFetch from "../../hooks/useSwrFetch";

const DashBoard = () => {
  const { data, loading } = useSWRFetch("/admin/dashboard/statistics");
  const { data: graph, loading: loading2 } = useSWRFetch(
    "/admin/dashboard/graph"
  );
  const { data: recent, loading: loading3 } = useSWRFetch(
    "/admin/dashboard/recently-added"
  );

  const resetGraph = graph?.data?.map(e => {
    return {
      month: e.month.split("T")[0],
      ListedProperties: parseInt(e?.listed_properties),
      SoldProperties: parseInt(e?.sold_properties),
      RentedProperties: parseInt(e?.rented_properties)
    };
  });

  return (
    <div className="">
      {(loading || loading2 || loading3) && <FullLoader />}

      <h1 className="text-xl font-semibold mb-10">Welcome Gift</h1>
      <div className="grid grid-cols-4 w-full gap-10">
        <div className="border rounded-md py-10">
          <div className="flex items-center justify-center">
            <img
              src={require("../../assets/images/users.svg").default}
              alt="users"
            />

            <h1 className="text-2xl font-bold ml-5">Users</h1>
          </div>

          <h3 className="text-upBlack font-bold text-3xl text-center mt-5">
            {data?.data?.number_of_users}
          </h3>
        </div>
        <div className="border rounded-md py-10">
          <div className="flex items-center justify-center">
            <img
              src={require("../../assets/images/building.svg").default}
              alt="users"
            />

            <h1 className="text-2xl font-bold ml-5">Properties</h1>
          </div>

          <h3 className="text-upBlack font-bold text-3xl text-center mt-5">
            {data?.data?.properties}
          </h3>
        </div>{" "}
        <div className="border rounded-md py-10">
          <div className="flex items-center justify-center">
            <img
              src={require("../../assets/images/building.svg").default}
              alt="users"
            />

            <h1 className="text-2xl font-bold ml-5">New Properties</h1>
          </div>

          <h3 className="text-upBlack font-bold text-3xl text-center mt-5">
            {data?.data?.new_properties}
          </h3>
        </div>{" "}
        <div className="border rounded-md py-10">
          <div className="flex items-center justify-center">
            <img
              src={require("../../assets/images/building.svg").default}
              alt="users"
            />

            <h1 className="text-2xl font-bold ml-5">Properties Sold</h1>
          </div>

          <h3 className="text-upBlack font-bold text-3xl text-center mt-5">
            {data?.data?.sold_properties}
          </h3>
        </div>{" "}
      </div>

      <div className="grid dash-graph gap-10 my-10">
        <div className="w-full border h-full">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={resetGraph}
              margin={{ top: 10, right: 10, left: -10, bottom: 5 }}
            >
              <CartesianGrid horizontal={false} />
              <XAxis dataKey="month" />
              <YAxis dataKey="ListedProperties" />
              <Tooltip />
              <Legend />
              <Bar dataKey="ListedProperties" fill="#0E113D" barSize={20} />
              <Bar dataKey="SoldProperties" fill="#3BB54A" barSize={20} />
              <Bar dataKey="RentedProperties" fill="#FF0000" barSize={20} />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="w-full border h-full p-3">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-sm">Recently Added Properties</h1>

            {/* <p className="text-upBlack text-sm">View all</p> */}
          </div>

          <div className="mt-5 w-full dash-recent-list space-y-2">
            {recent?.data?.length ? (
              <>
                {recent?.data?.map((r, i) => (
                  <DashRecentListing key={i} {...r} />
                ))}
              </>
            ) : (
              <p className="text-center font-semibold mt-20 text-lg">
                No recent property
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
