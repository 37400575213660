import React, { useState, useEffect } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { Form1 } from "./Form1";
import backArrow from "../../assets/images/arrow-left.svg";
import { createAdvert, getPropertyList } from "../../utils/ApiRequests";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export const AddAdvertisement = () => {
  const [imgFile, setImgFile] = useState();
  const [allProperties, setAllProperties] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [propertyId, setPropertyId] = useState();
  const [submitting, setSubmitting] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const fetchAllProperties = async () => {
      try {
        const res = await getPropertyList();
        // console.log(res.data);
        setAllProperties(res.data.data);
      } catch (error) {
        console.log(error.response);
      }
    };

    fetchAllProperties();
  }, []);

  const clearForm = () => {
    setTo();
    setFrom();
    setImgFile();
  };

  const submitAdvertForm = async e => {
    e.preventDefault();
    setSubmitting(true);

    let bodyFormData = new FormData();
    bodyFormData.append("property_id", parseInt(propertyId));
    bodyFormData.append("file", imgFile);
    bodyFormData.append("from", new Date(from));
    bodyFormData.append("end", new Date(to));
    try {
      await createAdvert(bodyFormData);
      // console.log(res.data);
      setSubmitting(false);
      toast.success("Advert created successfully ");
      clearForm();
    } catch (error) {
      console.log(error.response);
      setSubmitting(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <div
        className="back-arrow flex cursor-pointer w-min"
        onClick={() => history.goBack()}
      >
        <img src={backArrow} alt="" />
        <div className="font-normal ml-2 my-auto">Back</div>
      </div>

      <div className="edit-wrapper">
        <div className=" w-max my-5">
          <h1 className="text-xl">Add Advertisement</h1>
          <AccentBar width="80%" />
        </div>

        <div className="shadow p-5 my-10">
          <Form1
            imgFile={imgFile}
            setImgFile={setImgFile}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            allProperties={allProperties}
            submitAdvertForm={submitAdvertForm}
            propertyId={propertyId}
            setPropertyId={setPropertyId}
            submitting={submitting}
          />
        </div>
      </div>
    </>
  );
};
