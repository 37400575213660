import React, { useState, useEffect } from "react";
import { HistoryItem } from "../../components/Advertisement/HistoryItem";
import { getAdvertHistory } from "../../utils/ApiRequests";
import FullLoader from "../../components/Loaders/FullLoader";

export const AdvertisementHistory = () => {
  const [loading, setLoading] = useState(true);
  const [adHistory, setAdHistory] = useState();
  const [selectedId, setSelectedId] = useState();

  useEffect(() => {
    const fetchAdvertHistory = async () => {
      try {
        const { data } = await getAdvertHistory();
        // console.log(data);
        setLoading(false);
        setAdHistory(data.data);
      } catch (error) {
        setLoading(false);
        console.error(error.response);
      }
    };
    fetchAdvertHistory();
  }, []);

  if (loading) {
    return <FullLoader />;
  }

  const closeReactivateModal = () => {
    setSelectedId("");
  };

  return (
    <>
      <div className="history_table shadow  ">
        <div className="table_head rounded w-full py-3 border p-5 flex ">
          <div className="w-1/5">Advertisement Title</div>
          <div className="w-1/5">Advertisement Type</div>
          <div className="w-1/5">From</div>
          <div className="w-1/5">To</div>
          <div className="w-1/5">Actions</div>
        </div>
        {adHistory?.map(advert => (
          <HistoryItem
            id={advert.id}
            key={advert.id}
            propertyName={advert.property.name}
            type={advert.type}
            from={advert.start_date}
            to={advert.end_date}
            closeReactivateModal={closeReactivateModal}
            showReactivateModal={selectedId === advert.id}
            setSelectedId={setSelectedId}
          />
        ))}
        {adHistory?.length === 0 && (
          <div className="text-center py-10">No available advert</div>
        )}
      </div>
    </>
  );
};
