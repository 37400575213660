import React, { useEffect, useState } from "react";
import { getAllBlogs } from "../../utils/ApiRequests";
import { LegalItem } from "../../components/Legal/LegalContent";
import FullLoader from "../../components/Loaders/FullLoader";

export const Blog = () => {
  const [blogs, setBlogs] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchBlogs = async () => {
      try {
        const res = await getAllBlogs();
        // console.log(res);
        setLoading(false);
        setBlogs(res.data.data);
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error.response);
          setLoading(false);
        }
      }
    };
    fetchBlogs();
    return () => {
      abortController.abort();
    };
  }, []);

  if (loading) {
    return <FullLoader />;
  }
  return (
    <>
      {blogs?.length > 0 ? (
        blogs.map(blog => (
          <LegalItem
            title={blog.title}
            id={blog.id}
            content={blog.content}
            image={blog.image}
          />
        ))
      ) : (
        <div className="p-5 shadow">No blog available</div>
      )}
    </>
  );
};
