import { FiEdit } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";
import { EditSubscriptionModal } from "./EditSubscriptionModal";

export const FeatureItem = ({
  planName,
  features,
  yearAmount,
  monthAmount,
  deleteAction,
  openEditModal,
  description,
  propertyNo,
  id,
  selectedId,
  closeModal,
  setUpdate,
}) => {
  return (
    <>
      <div className="px-4 py-6 my-4 flex border rounded">
        <h2 className="w-1/5 text-xl font-black my-auto">{planName}</h2>
        <div className="w-1/5 text-center flex my-auto  justify-center">
          {features}
        </div>

        <div className="w-1/5 text-center flex  my-auto justify-center">
          {yearAmount}
        </div>
        <div className="w-1/5 text-center flex my-auto  justify-center">
          {monthAmount}
        </div>
        <div className="w-1/5 text-center w-min mx-auto ">
          <div
            className="edit-action flex px-4 justify-center my-3 cursor-pointer py-1 text-white rounded-3xl"
            onClick={() => openEditModal(id)}
            style={{ background: "#5D1763" }}
          >
            <FiEdit style={{ margin: "auto 8px auto 0" }} /> EDIT
          </div>
          <div
            className="edit-action flex px-4 justify-center my-3 cursor-pointer py-1  rounded-3xl"
            onClick={() => deleteAction(id)}
            style={{
              border: "1px solid #5D1763",
              color: "#5D1763",
              fontWeight: "bolder",
            }}
          >
            <FaTrashAlt style={{ margin: "auto 8px auto 0" }} /> DELETE
          </div>
        </div>
      </div>
      <EditSubscriptionModal
        showModal={selectedId === id}
        closeModal={closeModal}
        selectedId={selectedId}
        id={id}
        data={{
          name: planName,
          monthlyprice: monthAmount,
          yearlyPrice: yearAmount,
          features: features,
          description: description,
          propertyNo: propertyNo,
        }}
        setUpdate={setUpdate}
      />
    </>
  );
};
