import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import MiniLoader from "../../components/Loaders/MiniLoader";
import { login } from "../../utils/ApiRequests";
import { setTokenToStorage } from "../../utils/ApiUtils";
import { Input } from "../../components/InputFields/Input";

import eyeClosed from "../../assets/images/eyeClosed.svg";
import eyeOpened from "../../assets/images/eyeOpen.svg";
import { Link } from "react-router-dom";
import AuthLayout from "../../components/Layouts/AuthLayout";



const Login = () => {
  const [loginForm, setLoginForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }

  const history = useHistory();

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const newEntry = { [name]: value };
    setLoginForm({ ...loginForm, ...newEntry });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    login(loginForm)
      .then(res => {
        setTokenToStorage(res.data.data.token);
        toast.success("Login successful");
        history.push("/dashboard");
      })
      .catch(err => {
        toast.error("Invalid Email or Password");
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthLayout title='Login' >

      <form className=" mt-6 " onSubmit={handleSubmit}>

        <div className="mt-5 flex flex-row-reverse items-center relative">
          <Input
            onChange={handleChange}
            type="text"
            autoComplete="on"
            placeholder="Email"
            name="email"
            required
          />
        </div>



        <div className="mt-5 flex flex-row-reverse items-center relative  text-center ">
          <i className="absolute cursor-pointer" onClick={togglePasswordVisibility}>
            <img
              src={passwordShown ? eyeOpened : eyeClosed}
              alt="visible"
              className=" w-6 visibility mb-9 leftIcon"
            />
          </i>
          <Input
            onChange={handleChange}
            name="password"
            type={passwordShown ? "text" : "password"}
            label="Password"
            autoComplete="on"
            placeholder="Password"
            required
          />
        </div>


        <Link to="/forgotpassword" className="">
          <p className="underline w-max ml-auto text-dimGrey hover:no-underline hover:text-lightBlack text-sm text-right mt-2">
            Forgot password ? Click <span> here </span>to reset the password
          </p>
        </Link>

        {/* <p className="underline text-gray-400 text-sm text-right mt-2">
              Forgot password? Click here to reset the password
            </p> */}

        {loading ? (
          <MiniLoader />
        ) : (
          <button className="w-1/4 mx-auto block bg-primary mt-5 text-white rounded-md x-lg font-semibold py-3 min-w-  ">
            Log In
          </button>
        )}
      </form>
    </AuthLayout>


  );
};

export default Login;


// const LoginNew = () => {

//   return (
//     <AuthLayout>
//       <Intro
//         title="Welcome Back"
//         subtitle="Provide your email and password to login to the system"
//       />
//       <form
//         className="mt-12 m-auto w-11/12 md:w-8/12"
//         onSubmit={handleSubmit(handleLogin)}
//       >
//         <Input
//           type="email"
//           placeholder="example@example.com"
//           label="Email"
//           error={errors.email?.message}
//           {...register("email")}
//         />
//         <div className="w-full flex flex-row-reverse items-center relative">
//           <i className="absolute cursor-pointer" onClick={togglePasswordVisibility}>
//             <img
//               src={passwordShown ? eyeOpened : eyeClosed}
//               alt="visible"
//               className=" w-6 visibility mb-9 leftIcon"
//             />
//           </i>
//           <Input
//             placeholder="xxxxxxx"
//             type={passwordShown ? "text" : "password"}
//             label="Password"
//             error={errors.password?.message}
//             autocomplete="on"
//             {...register("password")}
//           />
//         </div>
//         <Link to="/forgotpassword" className="">
//           <p className="underline w-max ml-auto text-dimGrey hover:no-underline hover:text-lightBlack text-sm text-right mt-2">
//             Forgot password ? Click <span> here </span>to reset the password
//           </p>
//         </Link>
//         <Button loading={loading} type={'submit'} className='text-white ' buttonText="Log in" />
//       </form>

//       <Link to="/register">
//         <p className="text-center font-normal text-sm">
//           Don't have an account?
//           <span className="text-primary font-bold mx-1"> Sign Up</span>
//         </p>
//       </Link>

//       <div className="m-auto mb-24 lg:mb-0 w-11/12 md:w-8/12">
//         <p className="text-primary in-between font-bold font-sm my-12">or</p>

//         <div className="flex justify-center rounded-md border border-primary p-3">
//           <img src={gmail} alt="gmail" className="mr-4" />
//           <p className="font-semibold text-base text-center cursor-pointer">
//             Log in with Google
//           </p>
//         </div>
//       </div>
//     </AuthLayout>
//   )
// }



