import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import FullLoader from "../../components/Loaders/FullLoader";
import { editBlog, getSingleBlog } from "../../utils/ApiRequests";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FormInput } from "../../components/FormInput/FormInput";
import MiniLoader from "../../components/Loaders/MiniLoader";

export const EditBlog = () => {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [initialImg, setInitialImg] = useState();
  const [imgFile, setImgFile] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const getBlog = async () => {
      try {
        const { data } = await getSingleBlog(id);
        setLoading(false);
        // setBlog(data.data);
        setTitle(data.data.title);
        setContent(data.data.content);
        setInitialImg(data.data.image);
        // console.log(data.data);
      } catch (error) {
        setLoading(false);
        console.log(error.response);
      }
    };
    getBlog();
  }, [id]);

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    let bodyFormData = new FormData();
    bodyFormData.append("file", imgFile);
    bodyFormData.append("content", content);

    try {
      const res = await editBlog(id, bodyFormData);
      // console.log(res);
      setSubmitting(false);
      history.push(`/legal/blog/${id}`);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.data);
      setSubmitting(false);
    }
  };

  if (loading) {
    return <FullLoader />;
  }

  return (
    <>
      <div
        className="back-arrow flex cursor-pointer w-min"
        onClick={() => history.goBack()}
      >
        <AiOutlineArrowLeft style={{ margin: "auto" }} />
        <div className="font-normal ml-2 my-auto">Back</div>
      </div>

      <div className="w-max my-5">
        <h1 className="text-xl font-black">Edit Blog</h1>
        <AccentBar width="50%" />
      </div>

      <div className="my-10">
        <div className="shadow p-5 my-10">
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="w-1/2 pr-5">
                <FormInput
                  label="Blog Title"
                  value={title}
                  readOnly
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                  required={true}
                  type="text"
                />
              </div>
              {/* <div className="w-1/2 pr-5">
                <FormInput label="Publish Date" required={true} type="date" />
              </div> */}
            </div>
            {/* <div className="flex">
              <div className="w-1/2 pr-5">
                <FormInput label="Author’s Name" required={true} type="text" />
              </div>
            </div> */}
            <div className="flex my-5">
              <div className="image-input w-1/2 pr-5">
                <label className="block"> Picture</label>

                <div className="contact-file-input">
                  <label htmlFor="file-upload" className="custom-file-upload">
                    <div className="file border border-gray-200 border-dashed text-center w-full p-5 ">
                      {imgFile ? (
                        <img
                          src={URL.createObjectURL(imgFile)}
                          className="m-auto"
                          alt=""
                        />
                      ) : (
                        <img src={initialImg} alt="blog banner" />
                      )}
                    </div>
                  </label>

                  <input
                    id="file-upload"
                    className="hidden"
                    required
                    onChange={e => {
                      const [file] = e.target.files;
                      setImgFile(file);
                    }}
                    type="file"
                    accept=".png, .jpeg, .jpg"
                  />
                </div>
                {/* {!imgFile && (
                  <div className="text-sm  mt-3" style={{ color: "red" }}>
                    Please select an image
                  </div>
                )} */}
              </div>

              <div className="content w-1/2">
                <label className="block">Content</label>
                <textarea
                  className="bg-gray-100 p-3 w-full"
                  name="content"
                  required
                  value={content}
                  onChange={e => {
                    setContent(e.target.value);
                  }}
                  placeholder="Enter content"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="submit-btn mb-5 mt-8">
              {submitting ? (
                <MiniLoader />
              ) : (
                <CustomButton buttonText="Save" type="submit" />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
