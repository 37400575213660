import React from "react";

const PropertySingleBidListing = ({
  createdAt,
  user,
  amount,
  highest,
  counterBid,
  counterBidId,
  status
}) => {
  const { first_name, last_name, email } = user || {};

  return (
    <div className="bid-list-table border-b border-gray-300 items-center  ">
      {/* {highest === amount ? (
        <img
          src={require("../../assets/images/top.svg").default}
          alt="Top Bid"
          className="w-full self-start"
        />
      ) : */}
      {/* ( */}
      <div></div>
      {/* )} */}

      <div className="flex gap-3 py-2">
        <img
          src={user?.avatar?.url}
          alt="Profile"
          className="w-16 h-16 object-cover rounded-full"
        />

        <div className="space-y-1 self-center break-words">
          <h3 className="font-semibold text-lg">
            {first_name} {last_name}
          </h3>
          <h3 className="text-sm break-words">{email}</h3>
        </div>
      </div>

      <h2 className="text-center">
        {new Date(createdAt).toLocaleDateString()}
      </h2>
      <h2 className="text-center">
        ₦{parseInt(amount).toLocaleString("en-NG")}
      </h2>
      <h2 className="text-center">
        ₦{counterBidId ? parseInt(counterBid?.amount).toLocaleString("en-NG") : '0'}
      </h2>

      <div>
        <button className={`py-2 w-full text-center text-sm font-semibold text-white  rounded-3xl ${status === 'successful' ? 'bg-upGreen' : (status === 'countered' ? 'bg-red-500' : 'bg-yellow')} `}>
          {status?.toUpperCase() || "INACTIVE"}
        </button>
      </div>
    </div>
  );
};

export default PropertySingleBidListing;
