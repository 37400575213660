import React, { useEffect, useState } from "react";
import { SettingsItem } from "../../components/Settings/SettingsItems";
import {
  deleteBoostingPlan,
  getBoostingPlans,
  getUserLocation,
  updateUserLocation
} from "../../utils/ApiRequests";
import FullLoader from "../../components/Loaders/FullLoader";
import updateIcon from "../../assets/link-icons/update.svg";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";

export const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState();
  const [deleting, setDeleting] = useState(false);
  const [locations, setLocations] = useState();
  const [newLocation, setNewLocation] = useState("");
  const [updating, setUpdating] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const fetchBoostindPlans = async () => {
      try {
        const res = await getBoostingPlans();
        setLoading(false);
        setPlans(res.data.data);
      } catch (error) {
        console.error(error.response);
        setLoading(false);
      }
    };
    fetchBoostindPlans();

    const getAllLocations = async () => {
      try {
        const { data } = await getUserLocation();
        setLocations(data.data);
      } catch (error) {
        console.error(error.response);
      }
    };
    getAllLocations();
  }, [deleting, updating]);

  const handleDeletePlan = async id => {
    setDeleting(true);
    try {
      await deleteBoostingPlan(id);
      // console.log(res.data);
      setDeleting(false);
    } catch (error) {
      console.error(error.response);
      setDeleting(false);
    }
  };

  const handleNewLocation = async e => {
    e.preventDefault();
    const formData = {
      locations: [...locations, newLocation]
    };
    setUpdating(true);
    try {
     await updateUserLocation(formData);
      // console.log(data);
      setUpdating(false);
      setNewLocation("");
    } catch (error) {
      console.error(error.response);
      setUpdating(false);
    }
  };

  if (loading) {
    return <FullLoader />;
  }
  if (deleting) {
    return <FullLoader />;
  }
  if (updating) {
    return <FullLoader />;
  }
  return (
    <>
      <div className="font-bold text-2xl mb-8 text-gray-800 border-b-4 border-gray-800 w-min">
        Settings
      </div>
      <div className="settings-box-wrapper border border-gray-200 rounded-lg p-8">
        <h1 className="text-lg font-bold">Manage Locations of Users</h1>
        <p className="font-thin text-sm mb-5">
          Specify the location where the user can register themselves to sell
          and purchase the property.
        </p>
        <div className="font-bold my-3">Locations</div>

        <div className="my-4 flex flex-wrap">
          {locations?.map(location => (
            <div className="rounded py-2 mr-1 px-3 bg-gray-200 w-min">
              {location}
            </div>
          ))}
        </div>

        <form onSubmit={handleNewLocation}>
          <div className="flex w-full">
            <input
              type="text"
              onChange={e => setNewLocation(e.target.value)}
              placeholder="Add location"
              value={newLocation}
              required
              className=" border p-2 border-gray-200 w-2/4 rounded"
            />
            <button
              type="submit"
              className="rounded p-3 ml-3"
              style={{ background: "#1595D0", color: "white" }}
            >
              <div className="flex">
                <img src={updateIcon} alt="update" />
                <p className="my-auto ml-2">Update</p>
              </div>
            </button>
          </div>
        </form>

        <div className=" flex justify-between font-bold mt-8 mb-3 font-bold">
          <h2 className="my-auto">Boost Price Management</h2>
          <CustomButton
            buttonText="Add Boost Plan"
            onClick={() => history.push("/settings/add-plan")}
          />
        </div>
        <div className="border border-gray-200 p-5 rounded-lg">
          <div className="table-header flex w-full justify-between">
            <h2 className="w-1/4 font-bold">Category</h2>
            <h2 className="w-1/4 font-bold">Time</h2>
            <h2 className="w-1/4 font-bold">Price</h2>
            <h2 className="w-1/4 font-bold mx-auto text-center">Action</h2>
          </div>
          {plans?.map(plan => (
            <SettingsItem
              plan={plan}
              editPlan={() => history.push(`/settings/edit/${plan.id}`)}
              deletePlan={handleDeletePlan}
            />
          ))}
        </div>
      </div>
    </>
  );
};
