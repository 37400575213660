import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import ForgotPassword from "../pages/auth/ForgotPassword"
import Login from "../pages/auth/Login"
import { checkLogin } from "../utils/ApiUtils"

export const AuthRoutesList = [
  {
    path: "/login",
    component: Login,
    exact: true
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
    exact: true
  }
]

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !checkLogin() ? <Component {...props} /> : <Redirect to="/dashboard" />
      }
    />
  )
}

const AuthRoutes = () => {
  return (
    <Switch>
      {AuthRoutesList.map(r => (
        <AuthRoute
          path={r.path}
          exact={true}
          component={r.component}
          key={r.path}
        />
      ))}
    </Switch>
  )
}

export default AuthRoutes
