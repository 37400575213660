import React, { useState } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { FormInput } from "../../components/FormInput/FormInput";
import imgIcon from "../../assets/images/picture-icon.svg";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { createNews } from "../../utils/ApiRequests";
import MiniLoader from "../../components/Loaders/MiniLoader";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export const AddNews = () => {
  const [imgFile, setImgFile] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const clearForm = () => {
    setImgFile();
    setTitle("");
    setContent("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("title", title);
    bodyFormData.append("file", imgFile);
    bodyFormData.append("content", content);

    try {
      const res = await createNews(bodyFormData);
      console.log(res);
      setLoading(false);
      toast.success(res.data.message);
      clearForm();
    } catch (error) {
      console.log(error.response);
      setLoading(false);
      toast.error(error.response.data.data);
    }
  };
  return (
    <>
      <div
        className="back-arrow flex cursor-pointer w-min"
        onClick={() => history.goBack()}
      >
        <AiOutlineArrowLeft style={{ margin: "auto" }} />
        <div className="font-normal ml-2 my-auto">Back</div>
      </div>
      <div className="edit-wrapper">
        <div className="w-max my-5">
          <h1 className="text-xl">Add News</h1>
          <AccentBar width="50%" />
        </div>

        <div className="shadow p-5 my-10">
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <div className="w-1/2 pr-5">
                <FormInput
                  label="News Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required={true}
                  type="text"
                />
              </div>
              {/* <div className="w-1/2 pr-5">
                <FormInput label="Publish Date" required={true} type="date" />
              </div> */}
            </div>
            {/* <div className="flex">
              <div className="w-1/2 pr-5">
                <FormInput label="Author’s Name" required={true} type="text" />
              </div>
            </div> */}
            <div className="flex my-5">
              <div className="image-input w-1/2 pr-5">
                <label className="block"> Picture</label>

                <div className="contact-file-input">
                  <label htmlFor="file-upload" className="custom-file-upload">
                    <div className="file border border-gray-200 border-dashed text-center w-full p-5 ">
                      {imgFile ? (
                        <img
                          src={URL.createObjectURL(imgFile)}
                          className="m-auto"
                          alt=""
                        />
                      ) : (
                        <img src={imgIcon} alt="" className="m-auto py-16" />
                      )}
                    </div>
                  </label>

                  <input
                    id="file-upload"
                    className="hidden"
                    required
                    onChange={(e) => {
                      const [file] = e.target.files;
                      setImgFile(file);
                    }}
                    type="file"
                    accept=".png, .jpeg, .jpg"
                  />
                </div>
                {!imgFile && (
                  <div className="text-sm mt-3" style={{ color: "red" }}>
                    Please select an image
                  </div>
                )}
              </div>

              <div className="content w-1/2">
                <label className="block">Content</label>
                <textarea
                  className="bg-gray-100 p-3 w-full"
                  name="content"
                  required
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="Enter content"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="submit-btn mb-5 mt-8">
              {loading ? (
                <MiniLoader />
              ) : (
                <CustomButton buttonText="Publish" type="submit" />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
