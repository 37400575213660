import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { CustomButton } from "../../components/CustomButton/CustomButton";

import FullLoader from "../../components/Loaders/FullLoader";
import useSWRFetch from "../../hooks/useSwrFetch";
import { BidSubscriptionItem } from "./BidSubscriptionItem";

export const BidSubscriptions = () => {
  const history = useHistory();

  const { data: allSubscriptions, loading } = useSWRFetch(
    `/bid/token-plans`
  );


  if (loading) {
    return <FullLoader />;
  }
  return (
    <div className="subscription__page">
      <div className="flex justify-between my-5">
        <div className="page-header my-auto">
          <h1 className="text-xl"> Bid Subscription</h1>
          <AccentBar width="100%" />
        </div>
        <CustomButton
          buttonText="Add Subscription"
          onClick={() => history.push("/bids/subscriptions/add")}
        />
      </div>

      <div className="my-10">
        {allSubscriptions?.data?.map((subscription) => {
          return (
            <BidSubscriptionItem
              key={subscription.id}
              id={subscription.id}
              name={subscription.name}
              icon={subscription.image}
              subscribers={subscription.user_count}
            />
          );
        })}
      </div>
    </div>
  );
};
