import { useHistory } from "react-router-dom";

export const ActiveAdItem = ({
  propertyName,
  from,
  to,
  status,
  deActivate,
  id,
}) => {
  const history = useHistory();
  return (
    <div className="history_table ">
      <div className="table_head rounded w-full py-3 border p-5 flex ">
        <div
          className="w-1/5 my-auto cursor-pointer"
          onClick={() => history.push(`/ads/${id}`)}
        >
          {propertyName}
        </div>
        <div className="w-1/5 my-auto">{new Date(from).toDateString()}</div>
        <div className="w-1/5 my-auto">{new Date(to).toDateString()}</div>
        <div
          className="w-1/5 px-7 py-2 w-min mx-auto rounded-3xl text-white font-bold"
          style={{ background: "#29CC97", textTransform: "uppercase" }}
        >
          {status}
        </div>
        <div
          className="w-1/5 px-5 py-2 w-min cursor-pointer mx-auto rounded-3xl text-white font-bold"
          style={{ background: "#F90217", textTransform: "uppercase" }}
          onClick={() => deActivate(id)}
        >
          Deactivate
        </div>
      </div>
    </div>
  );
};
