import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import backArrow from "../../assets/images/arrow-left.svg";
// import { FormInput } from "../../components/FormInput/FormInput";
import { FeatureItem } from "../../components/Subscriptions/FeatureItem";
// import { CustomButton } from "../../components/CustomButton/CustomButton";
import {
  deleteSubscriptionPlan,
  getAllSubscription,
  // updateSubscriptionPlan,
} from "../../utils/ApiRequests";
import FullLoader from "../../components/Loaders/FullLoader";
// import { EditSubscriptionModal } from "../../components/Subscriptions/EditSubscriptionModal";

export const ManageSubscription = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [allSubscriptions, setAllSubscriptions] = useState();
  const [selectedId, setSelectedId] = useState("");
  // const [openModal, setOpenModal] = useState(false);
  const [update, setUpdate] = useState(false);

  const handleDeletePlan = async (id) => {
    setDeleting(true);
    try {
      const res = await deleteSubscriptionPlan(id);
      console.log(res.data);
      setDeleting(false);
    } catch (error) {
      console.error(error.response);
      setDeleting(false);
    }
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const res = await getAllSubscription();
        setAllSubscriptions(res.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.response);
        setLoading(false);
      }
    };
    fetchSubscriptions();
  }, [deleting, update]);

  if (loading || deleting || update) {
    return <FullLoader />;
  }
 

  const openEditModal = (id) => {
    setSelectedId(id);
  };
  const closeEditModal = () => {
    setSelectedId("");
  };

  return (
    <>
      <div
        className="back-arrow mb-5 flex cursor-pointer w-min"
        onClick={() => history.goBack()}
      >
        <img src={backArrow} alt="" />
        <div className="font-normal ml-2 my-auto">Back</div>
      </div>

      <div className="subscription-title w-max">
        <h1 className="font-bold text-lg">Manage Subscription</h1>
        <AccentBar width="100%" />
      </div>

      {/* subscription table */}

      <div className="sub__manage-table w-full shadow p-5 my-5 rounded">
        <div className="flex border-b-gray w-full">
          <div className="w-1/5 font-bold">Plan</div>
          <div className="w-1/5 text-center font-bold">Feature</div>
          <div className="w-1/5 text-center font-bold">Amount/year</div>
          <div className="w-1/5 text-center font-bold">Amount/month</div>
          <div className="w-1/5 text-center font-bold">Action</div>
        </div>

        <div className="feature-lists">
          {allSubscriptions?.map((el) => {
            return (
              <FeatureItem
                key={el.id}
                planName={el.name}
                id={el.id}
                monthAmount={el.amount_per_month}
                yearAmount={el.amount_per_year}
                features={el.attributes}
                description={el.description}
                propertyNo={el.number_of_property}
                openEditModal={openEditModal}
                selectedId={selectedId}
                deleteAction={handleDeletePlan}
                closeModal={closeEditModal}
                setUpdate={setUpdate}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
