import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import FullLoader from "../../components/Loaders/FullLoader";
import { editRegulation, getSingleRegulation } from "../../utils/ApiRequests";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FormInput } from "../../components/FormInput/FormInput";
import MiniLoader from "../../components/Loaders/MiniLoader";

export const EditRegulation = () => {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const getRegulation = async () => {
      try {
        const { data } = await getSingleRegulation(id);
        setLoading(false);
        setTitle(data.data.title);
        setContent(data.data.content);
        console.log(data);
      } catch (error) {
        setLoading(false);
        console.log(error.response);
      }
    };
    getRegulation();
  }, [id]);

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    const payload = {
      content: content
    };

    try {
      const res = await editRegulation(id, payload);
      // console.log(res);
      setSubmitting(false);
      history.push(`/legal/regulations/${id}`);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.data);
      setSubmitting(false);
    }
  };

  if (loading) {
    return <FullLoader />;
  }

  return (
    <>
      <div
        className="back-arrow flex cursor-pointer w-min"
        onClick={() => history.goBack()}
      >
        <AiOutlineArrowLeft style={{ margin: "auto" }} />
        <div className="font-normal ml-2 my-auto">Back</div>
      </div>

      <div className="w-max my-5">
        <h1 className="text-xl font-black">Edit Regulation</h1>
        <AccentBar width="50%" />
      </div>

      <div className="my-10">
        <div className="shadow p-5 my-10">
          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="pr-5">
                <FormInput
                  label="Blog Title"
                  value={title}
                  readOnly
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                  required={true}
                  type="text"
                />
              </div>

              <div className="my-5">
                <div className="content w-1/2">
                  <label className="block">Content</label>
                  <textarea
                    className="bg-gray-100 p-3 w-full"
                    name="content"
                    required
                    value={content}
                    onChange={e => {
                      setContent(e.target.value);
                    }}
                    placeholder="Enter content"
                    rows="10"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="submit-btn mb-5 mt-8">
              {submitting ? (
                <MiniLoader />
              ) : (
                <CustomButton buttonText="Save" type="submit" />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
