import React, { useEffect, useState } from "react";
import FullLoader from "../../components/Loaders/FullLoader";
import PropertyListing from "../../components/PropertyItems/PropertyListing";
// import useFetch from "../../hooks/useFetch";
// import { getPropertyList, getPropertyStats } from "../../utils/ApiRequests";
// import { paginator } from "../../utils/Paginator";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import useSWRFetch from "../../hooks/useSwrFetch";
import { useSWRConfig } from "swr";

const PropertiesIndex = () => {
  const [page, setPage] = useState(1);
  const { data: response, loading } = useSWRFetch("/admin/property/statistics");
  const { data: list, loading: loading2 } = useSWRFetch(
    `/admin/property?sort=DESC&page=${page}&size=10`
  );

  const { mutate } = useSWRConfig();

  const refetch = () =>
    mutate(`/admin/property?sort=DESC&page=${page}&size=10`);

  // console.log(list);

  const {
    total_property,
    active_properties,
    sold_properties,
    pending_properties
  } = response?.data || {};

  useEffect(() => {
    window.scroll(0, 0);
  }, [page]);

  console.log(list?.meta?.totalPages);

  return (
    <div>
      {(loading || loading2) && <FullLoader />}
      <h1 className="text-2xl font-bold page-title relative">Properties</h1>
      <div className="w-full grid grid-cols-4 gap-14 my-10">
        <div className="w-full  text-center py-10 text-white bg-upBlack space-y-2 rounded-md">
          <h2 className="text-lg font-bold">Total</h2>

          <h4 className="text-3xl font-semibold">{total_property}</h4>
        </div>

        <div className="w-full  text-center py-10 text-white bg-red-800 space-y-2 rounded-md">
          <h2 className="text-lg font-bold">Active</h2>

          <h4 className="text-3xl font-semibold">{active_properties}</h4>
        </div>

        <div className="w-full  text-center py-10 text-white bg-green-500 space-y-2 rounded-md">
          <h2 className="text-lg font-bold">Sold</h2>

          <h4 className="text-3xl font-semibold">{sold_properties}</h4>
        </div>

        <div className="w-full  text-center py-10 text-white bg-gray-500 space-y-2 rounded-md">
          <h2 className="text-lg font-bold">Under Review</h2>

          <h4 className="text-3xl font-semibold">{pending_properties}</h4>
        </div>
      </div>

      <div className="p-2 shadow-md rounded-md space-y-3">
        <div className="property-details  p-2 text-center font-bold">
          <h1 className="text-left">Property Details</h1>
          <h1>Added By</h1>
          <h1>Price</h1>
          <h1>Status</h1>
          <h1>Action</h1>
        </div>

        {list?.data?.map((l, i) => (
          <PropertyListing
            key={i}
            {...l}
            url={`/admin/properties/requests?sort=DESC&page=${page}`}
            mutate={refetch}
          />
        ))}

        <div className="flex justify-center items-center gap-10 w-full">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <IoIosArrowBack className="text-3xl " />
            <span
              className={`${
                page === 1 ? "text-black cursor-not-allowed" : "text-upRed"
              }`}
            >
              Previous
            </span>
          </div>

          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <span
              className={`${
                page === list?.meta?.totalPages
                  ? "text-black cursor-not-allowed"
                  : "text-upRed"
              }`}
            >
              Next
            </span>
            <IoIosArrowForward className="text-3xl " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertiesIndex;
