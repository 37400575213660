import React, { useState, useEffect } from "react";
// import { HistoryItem } from "../../components/Advertisement/HistoryItem";
import { deActivateAdvert, getActiveAdvert } from "../../utils/ApiRequests";
import FullLoader from "../../components/Loaders/FullLoader";
import { ActiveAdItem } from "../../components/Advertisement/ActiveAdItem";
import { toast } from "react-toastify";

export const ActiveAdvert = () => {
  const [loading, setLoading] = useState(true);
  const [activeAd, setActiveAd] = useState();
  const [deactivating, setDeactivating] = useState(false);

  useEffect(() => {
    const fetchActiveAdvert = async () => {
      try {
        const { data } = await getActiveAdvert();
        // console.log(data);
        setLoading(false);
        setActiveAd(data.data);
      } catch (error) {
        setLoading(false);
        console.error(error.response);
      }
    };
    fetchActiveAdvert();
  }, [deactivating]);

  const deActivate = async id => {
    setDeactivating(true);
    try {
      const res = await deActivateAdvert(id);
      // console.log(res.data);
      toast.success(res.data.data);
      setDeactivating(false);
    } catch (error) {
      console.error(error.response);
      toast.error(error.response.data.message);
      setDeactivating(false);
    }
  };

  if (loading) {
    return <FullLoader />;
  }
  if (deactivating) {
    return <FullLoader />;
  }

  return (
    <>
      <div className="history_table shadow  ">
        <div className="table_head rounded w-full py-3 border p-5 flex ">
          <div className="w-1/5">Property</div>
          <div className="w-1/5 ml-2">From</div>
          <div className="w-1/5 ml-2">To</div>
          <div className="w-1/5 text-center">Status</div>
          <div className="w-1/5  text-center">Action</div>
        </div>
        {activeAd?.map(advert => (
          <ActiveAdItem
            key={advert.id}
            propertyName={advert.property.name}
            from={advert.start_date}
            to={advert.end_date}
            status={advert.status}
            id={advert.id}
            deActivate={deActivate}
          />
        ))}
        {activeAd?.length === 0 && (
          <div className="text-center py-10">No available advert</div>
        )}
      </div>
    </>
  );
};
