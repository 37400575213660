import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import GlobalLayout from "../components/GlobalLayout/GlobalLayout";
import { Advertisement } from "../pages/Advertisement/Advertisement";
import DashBoard from "../pages/Dashboard/DashBoard";
import { AddUser } from "../pages/InternalUsers/AddUser";
import { InternalUsers } from "../pages/InternalUsers/InternalUsers";
import { AddBlog } from "../pages/Legal/AddBlog";
import { Legal } from "../pages/Legal/Legal";
import { Settings } from "../pages/settings/Settings";
import EditProfile from "../pages/Profile/EditProfile";
import Profile from "../pages/Profile/Profile";
import PropertiesIndex from "../pages/Properties/PropertiesIndex";
import PropertyDetails from "../pages/Properties/PropertyDetails";
import Request from "../pages/Request/Request";
import BidsIndex from "../pages/Bids/BidsIndex";
import UsersIndex from "../pages/UserManagement/UsersIndex";
import { checkLogin } from "../utils/ApiUtils";
import { Subscriptions } from "../pages/Subscriptions/Subscriptions";
import { Subscribers } from "../pages/Subscriptions/Subscribers";
import { AddNews } from "../pages/Legal/AddNews";
import { AddRegulations } from "../pages/Legal/AddRegulations";
import { ManageSubscription } from "../pages/Subscriptions/ManageSubscription";
import { AddBoostPlan } from "../pages/settings/AddPlan";
import { EditBoostPlan } from "../pages/settings/EditPlan";
import { AddSubscription } from "../pages/Subscriptions/AddSubscription";
import { AddAdvertisement } from "../pages/Advertisement/AddAdvert";
import { SingleAdvert } from "../pages/Advertisement/SingleAdvert";
import { SingleNews } from "../pages/Legal/SingleNews";
import { SingleBlog } from "../pages/Legal/SingleBlog";
import { SingleRegulation } from "../pages/Legal/SingleRegulation";
import { EditBlog } from "../pages/Legal/EditBlog";
import { EditNews } from "../pages/Legal/EditNews";
import { EditRegulation } from "../pages/Legal/EditRegulation";
import SingleRequest from "../pages/Request/SingleRequest";
import SingleBidPage from "../pages/Bids/SingleBidPage";
import SingleUser from "../pages/UserManagement/SingleUser";
import { AddBidSubscription } from "../pages/Bids/AddBidSubscription";
import { BidSubscriptions } from "../pages/Bids/BidSubscriptions";

export const PrivateRoutesList = [
  { path: "/dashboard", component: DashBoard, exact: true },
  { path: "/users/:id", component: SingleUser, exact: true },
  { path: "/users", component: UsersIndex, exact: true },
  { path: "/settings", component: Settings, exact: true },
  { path: "/internal-users", component: InternalUsers, exact: true },
  { path: "/internal-users/add", component: AddUser, exact: true },
  { path: "/legal", component: Legal, exact: true },
  { path: "/bids", component: BidsIndex, exact: true },
  { path: "/bids/subscriptions", component: BidSubscriptions, exact: true },
  { path: "/bids/subscriptions/add", component: AddBidSubscription, exact: true },
  { path: "/bids/:id", component: SingleBidPage, exact: true },
  { path: "/legal/add-blog", component: AddBlog, exact: true },
  { path: "/legal/add-news", component: AddNews, exact: true },
  { path: "/legal/add-regulation", component: AddRegulations, exact: true },
  { path: "/legal/news/:id", component: SingleNews, exact: true },
  { path: "/legal/blog/:id", component: SingleBlog, exact: true },
  { path: "/legal/regulations/:id", component: SingleRegulation, exact: true },
  { path: "/legal/blog/:id/edit", component: EditBlog, exact: true },
  { path: "/legal/news/:id/edit", component: EditNews, exact: true },
  {
    path: "/legal/regulations/:id/edit",
    component: EditRegulation,
    exact: true
  },
  { path: "/ads", component: Advertisement, exact: true },
  { path: "/profile", component: Profile, exact: true },
  { path: "/profile/edit", component: EditProfile, exact: true },
  { path: "/properties", component: PropertiesIndex, exact: true },
  { path: "/requests/:id", component: SingleRequest, exact: true },
  { path: "/requests", component: Request, exact: true },
  { path: "/properties/details/:id", component: PropertyDetails, exact: true },
  { path: "/subscriptions", component: Subscriptions, exact: true },
  { path: "/subscriptions/manage", component: ManageSubscription, exact: true },
  { path: "/subscriptions/add", component: AddSubscription, exact: true },
  { path: "/subscriptions/:id", component: Subscribers, exact: true },
  { path: "/settings/add-plan", component: AddBoostPlan, exact: true },
  { path: "/settings/edit/:id", component: EditBoostPlan, exact: true },
  { path: "/ads/create", component: AddAdvertisement, exact: true },
  { path: "/ads/:id", component: SingleAdvert, exact: true },
  { path: "", component: () => <Redirect from="" to="/dashboard" /> }
];

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        checkLogin() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const PrivateRoutes = () => {
  return (
    <GlobalLayout>
      <Switch>
        {PrivateRoutesList.map(r => (
          <PrivateRoute
            path={r.path}
            exact={true}
            component={r.component}
            key={r.path}
          />
        ))}
      </Switch>
    </GlobalLayout>
  );
};

export default PrivateRoutes;
