import useSWR from "swr";
import { fetcher } from "../utils/ApiRequests";

const useSWRFetch = url => {
  const { data, error } = useSWR(url, fetcher);

  return { data, loading: !error && !data, error };
};

export default useSWRFetch;
