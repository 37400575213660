import React from "react";

export const AddUserToggleItem = ({
  itemName,
  hasProperty,
  setHasProperty,
  name,
  hasLegal,
  setHasLegal,
  hasUserMgt,
  setHasUserMgt,
  hasAdvert,
  setHasAdvert,
}) => {
  return (
    <div className="shadow flex justify-between p-5 my-4 rounded">
      {itemName}
      <div className="togglebtn">
        <div
          className="rounded-xl py-1 px-0.5 w-14 flex"
          style={{
            background: `${
              (
                name === "property"
                  ? hasProperty
                  : name === "user"
                  ? hasUserMgt
                  : name === "legal"
                  ? hasLegal
                  : hasAdvert
              )
                ? "#B3404A"
                : "#451a1e"
            }`,
          }}
        >
          <div
            className="w-1/2"
            onClick={() => {
                name === "property"
                  ? setHasProperty(false)
                  : name === "user"
                  ? setHasUserMgt(false)
                  : name === "legal"
                  ? setHasLegal(false)
                  : setHasAdvert(false);
            }}
          >
            {(name === "property"
              ? !hasProperty
              : name === "user"
              ? !hasUserMgt
              : name === "legal"
              ? !hasLegal
              : !hasAdvert) && (
              <div className="toggle-circle m-auto h-4 w-4 rounded-full bg-white"></div>
            )}
          </div>
          <div
            className="w-1/2"
            onClick={() => {
              name === "property"
                ? setHasProperty(true)
                : name === "user"
                ? setHasUserMgt(true)
                : name === "legal"
                ? setHasLegal(true)
                : setHasAdvert(true);
            }}
          >
            {(name === "property"
              ? hasProperty
              : name === "user"
              ? hasUserMgt
              : name === "legal"
              ? hasLegal
              : hasAdvert) && (
              <div className="toggle-circle m-auto h-4 w-4 rounded-full bg-white"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
