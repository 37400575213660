import React from "react";
import { IoLocationSharp } from "react-icons/io5";

const DashRecentListing = ({ price, city, address_line_one, images }) => {
  return (
    <div className="w-full p-2 flex items-center border rounded-md">
      <img
        className="flex-1 w-24 object-cover mr-3 rounded-md self-stretch"
        src={images.length ? images[0].image_url : null}
        alt="property listing"
      />

      <div className="w-full space-y-2">
        <h1 className="truncate font-bold text-sm">{address_line_one}</h1>

        <div className="flex items-center text-base space-x-1">
          <IoLocationSharp className="text-upRed" />

          <h1 className="text-gray-400 truncate">{city}</h1>
        </div>

        <h1 className="font-medium">
          ₦{parseInt(price).toLocaleString("en-NG")}
        </h1>
      </div>
    </div>
  );
};

export default DashRecentListing;
