import React, { useRef, useState } from "react";
// import Switch from "../GlobalLayout/Switch"
// import { FaRegEdit } from "react-icons/fa"
import ModalContainer from "../ModalContainer/ModalContainer";
import useClickOutside from "../../hooks/useClickOutside";
import FullLoader from "../Loaders/FullLoader";
import {
  approvePropertyDocument,
  rejectPropertyDocument
} from "../../utils/ApiRequests";
import { toast } from "react-toastify";

const PropertyDocument = ({
  name,
  createdAt,
  image_url,
  status,
  mutate,
  propertyId,
  id
}) => {
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);

  const DisplayImage = () => {
    const ref = useRef();
    useClickOutside(ref, () => setView(false));

    return (
      <ModalContainer>
        {
          <img
            src={image_url}
            alt={name}
            ref={ref}
            className="object-cover w-60vw h-4/5 border-0"
          />
        }
      </ModalContainer>
    );
  };

  return (
    <div className="document-table border-b border-gray-300 items-center text-center">
      {view && <DisplayImage />}

      {loading && <FullLoader />}

      <div className="flex items-center gap-3">
        <img
          src={require("../../assets/images/document.svg").default}
          alt="document"
        />

        <p>{name}</p>
      </div>

      <p className="">{new Date(createdAt).toLocaleDateString()}</p>

      <div className="flex justify-center">
        <a href={image_url} target="_blank" rel="noreferrer">
          <img
            src={require("../../assets/images/eye.svg").default}
            alt="Eyes"
            className="cursor-pointer"
            // onClick={() => window.}
          />
        </a>

        {/* <Switch
          state={view}
          onClick={() => {
            setView(!view)
          }}
        /> */}
      </div>

      <div>
        <button
          className={`py-2 w-full text-center text-sm font-semibold text-white ${
            status === "approved"
              ? "bg-upGreen"
              : status === "pending"
              ? "bg-yellow-400"
              : "bg-upRed"
          } rounded-3xl`}
        >
          {status?.toUpperCase() || "REJECTED"}
        </button>
      </div>

      <div>
        {status !== "approved" ? (
          <button
            className="py-2  w-full text-center text-sm font-semibold text-green-300 bg-white border border-green-200 rounded-3xl"
            onClick={() => {
              setLoading(true);
              approvePropertyDocument(propertyId, id)
                .then(() => {
                  toast.success("Document Approved Successfully");
                  mutate();
                })
                .catch(err => toast.error("Unable to approve document."))
                .finally(() => setLoading(false));
            }}
          >
            Approve
          </button>
        ) : (
          <button
            className="py-2  w-full text-center text-sm font-semibold text-red-400 bg-white border border-red-400 rounded-3xl"
            onClick={() => {
              setLoading(true);
              rejectPropertyDocument(propertyId, id)
                .then(() => {
                  toast.success("Document Rejected Successfully");
                  mutate();
                })
                .catch(err => toast.error("Unable to reject document."))
                .finally(() => setLoading(false));
            }}
          >
            DISAPPROVE
          </button>
        )}
      </div>
    </div>
  );
};

export default PropertyDocument;
