import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { useHistory, useParams } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import FullLoader from "../../components/Loaders/FullLoader";
import useSWRFetch from "../../hooks/useSwrFetch";

const SingleBidPage = () => {
  const { id } = useParams();
  const history = useHistory();
  // let { data,  } = useSWRFetch(`/admin/properties/bids/${id}`);
  const { data: response, loading } = useSWRFetch(
    // `/admin/properties/bids?sort=DESC&page=${page}&size=10`
    `/admin/bids?page=${1}&size=2`
  );
  let data = { data: response?.data?.data?.find(item => item?.id == id) || {} }
  console.log(data);
  return (
    <div className="px-5 py-5">
      {loading && <FullLoader />}

      <div
        className="flex items-center gap-2 text-xl cursor-pointer mb-5"
        onClick={() => history.goBack()}
      >
        <BsArrowLeft />
        Back
      </div>

      <div className="w-max">
        <h1 className="text-xl font-semibold">Bids</h1>
        <AccentBar width="90%" />
      </div>

      <div className="single-request-grid mt-10">
        <div className="space-y-4 ">
          {/* <img
            src={data?.data?.property?.images?.[0]?.image_url}
            alt={data?.data?.property?.address_line_one}
            className="w-full h-36 object-cover rounded-md"
          /> */}
          <h3 className="font-WorkSans font-semibold"> Details </h3>

          <p className="truncate font-bold text-sm w-">
            {data?.data?.property?.address_line_one}
          </p>

          <div className="flex items-center text-base space-x-1">
            <IoLocationSharp className="text-upRed" />

            <h1 className="text-gray-600 truncate">
              {data?.data?.property?.city}
            </h1>
          </div>

          <div className="flex items-center gap-2 ">
            <h4 className="font-bold">
              ₦ {parseInt(data?.data?.property?.price).toLocaleString("en-NG")}
            </h4>

          </div>
        </div>

        <div className="space-y-4 flex-1 w-full">
          <div className="flex w-full justify-between gap-5">

            <div className=" flex flex-col justify-between pb-5">
              <h1 className="font-WorkSans font-semibold">Status</h1>

              <div className="">
                <button className={`p-2 w-full text-center text-sm font-semibold text-white  rounded-3xl ${data?.data?.status === 'successful' ? 'bg-upGreen' : (data?.data?.status === 'countered' ? 'bg-red-500' : 'bg-yellow')} `}>
                  {data?.data?.status?.toUpperCase() || "INACTIVE"}
                </button>
              </div>
            </div>

            <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold">Owned By</h1>

              <div className="flex items-center gap-4">
                {/* <img
                  src={data?.data?.user?.avatar?.url}
                  alt={data?.data?.user?.avatar?.email}
                  className="h-32 w-32 rounded-full object-cover"
                /> */}

                <div className="space-y-1 font-WorkSans">
                  <h1 className="font-semibold break-words">
                    {data?.data?.user?.first_name} {data?.data?.user?.last_name}
                  </h1>
                  <h1 className="text-sm break-all">
                    {data?.data?.user?.email}
                  </h1>
                </div>
              </div>
            </div>

            {/* <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold">Owned By</h1>

              <div className="flex items-center gap-4">
                <img
                  src={data?.data?.user?.avatar?.url}
                  alt={data?.data?.user?.avatar?.email}
                  className="h-32 w-32 rounded-full object-cover"
                />

                <div className="space-y-1 font-WorkSans">
                  <h1 className="font-semibold break-words">
                    {data?.data?.user?.first_name} {data?.data?.user?.last_name}
                  </h1>
                  <h1 className="text-sm break-all">
                    {data?.data?.user?.email}
                  </h1>
                </div>
              </div>
            </div> */}

            <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold">Requested By</h1>

              <div className="flex items-center gap-4">
                {/* <img
                  src={data?.data?.user?.avatar?.url}
                  alt={data?.data?.user?.avatar?.email}
                  className="h-32 w-32 rounded-full object-cover"
                /> */}

                <div className="space-y-1 font-WorkSans">
                  <h1 className="font-semibold break-words">
                    {data?.data?.user?.first_name} {data?.data?.user?.last_name}
                  </h1>
                  <h1 className="text-sm break-all">
                    {data?.data?.user?.email}
                  </h1>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold"> Bid Amount </h1>

              <h1> ₦{parseInt(data?.data?.amount).toLocaleString("en-NG")}</h1>
            </div>

            <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold"> Countered Bid </h1>

              <h1> ₦{data?.data?.counterBidId ? parseInt(data?.data?.counterBid?.amount).toLocaleString("en-NG") : '0'} </h1>
            </div>

            {/* <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold">Highest Bid</h1>
              <h1> ₦{parseInt(data?.data?.highest).toLocaleString("en-NG")}</h1>
            </div> */}
            <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold">Received On</h1>

              <h1>{new Date(data?.data?.createdAt).toLocaleDateString()}</h1>
            </div>
          </div>

          {/* <div className="py-10 space-y-3">
            <h1 className="font-WorkSans font-semibold">Message</h1>
            <h1 className="font-WorkSans">{data?.data?.message}</h1>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SingleBidPage;
