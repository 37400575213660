import React from "react"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "./stylesheets/sass/_index.scss"
import "react-toastify/dist/ReactToastify.min.css"
import Routes from "./routes/Routes"
const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      ></ToastContainer>
      <Routes />
    </BrowserRouter>
  )
}

export default App
