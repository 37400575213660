import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from '../../components/InputFields/Input';
import AuthLayout from '../../components/Layouts/AuthLayout'
import MiniLoader from '../../components/Loaders/MiniLoader';
import { forgotPassword } from '../../utils/ApiRequests';




const ForgotPassword = () => {

  const [loginForm, setLoginForm] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory()

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const newEntry = { [name]: value };
    setLoginForm({ ...loginForm, ...newEntry });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    forgotPassword(loginForm)
      .then(function (response) {
        const successMessage = response.data.data;
        toast.success(successMessage);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          const errorMessage = error.response.data.data;
          toast.error(errorMessage);
          setLoading(false);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthLayout title='Password Recovery'>
      <form
        lassName="mt-12 w-11/12 md:w-8/12"
        onSubmit={handleSubmit}
      >
        <div className="mt-5 flex flex-row-reverse items-center relative">

          <Input
            onChange={handleChange}
            type="text"
            autoComplete="on"
            placeholder="Email"
            name="email"
            required
          />
        </div>

        <div className='text-dimGrey font-semibold w-max text-sm underline hover:no-underline '>
          <Link to='/login'  >
            Back to login
          </Link>
        </div>

        {loading ? (
          <MiniLoader />
        ) : (
          <button className="md:w-1/3  w-full mx-auto block bg-primary mt-5 text-white rounded-md x-lg font-semibold py-3 min-w-  ">
            Send Link to Email
          </button>
        )}

      </form>
    </AuthLayout>
  )
}

export default ForgotPassword