import React from "react";
import man from "../../assets/images/man.png";

export const Subscriber = ({ name, email, subscription }) => {
  return (
    <div className="flex w-full p-5 border border-b border-gray-300">
      <div className="user-info flex w-1/4">
        <img
          src={man}
          className="rounded-full h-11 w-11 mr-2"
          alt="user-avatar"
        />
        <div className="name-email ">
          <div className="text-lg"> {name} </div>
          <p className="text-sm text-gray-500"> {email} </p>
        </div>
      </div>
      <div className="w-1/4 my-auto">
        <div className="text-center ">
          {new Date(subscription.start_month).toDateString()}
        </div>
      </div>
      <div className="w-1/4 my-auto">
        <div className="text-center ">
          {" "}
          {new Date(subscription.end_month).toDateString()}{" "}
        </div>
      </div>
      <div className="w-1/4 text-center my-auto">
        <div
          className="px-5 w-min mx-auto py-1 rounded-lg text-center text-white"
          style={{ background: "#29CC97" }}
        >
          Acive
        </div>
      </div>
    </div>
  );
};
