import React, { useEffect, useState } from "react";
import { getAllNews } from "../../utils/ApiRequests";
import { NewsItem } from "../../components/Legal/LegalContent";
import FullLoader from "../../components/Loaders/FullLoader";

export const News = () => {
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchNews = async () => {
      try {
        const res = await getAllNews();
        // console.log(res);
        setLoading(false);
        setNews(res.data.data);
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error.response);
          setLoading(false);
        }
      }
    };
    fetchNews();
    return () => {
      abortController.abort();
    };
  }, []);

  if (loading) {
    return <FullLoader />;
  }
  return (
    <>
      {news?.length > 0 ? (
        news.map(newsItem => <NewsItem news={newsItem} />)
      ) : (
        <div className="p-5 shadow">No news available</div>
      )}
    </>
  );
};
