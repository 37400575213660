export const SuccessMessage = ({ successMessage }) => {
  return (
    <div
      className="p-5 rounded w-full border font-bold text-lg"
      style={{ background: "#b0ffb0" }}
    >
      {successMessage}
    </div>
  );
};
