import React from "react";
import man from "../../assets/images/man.png";
import trash from "../../assets/images/trash-bin.svg";

export const UserListItem = ({
  id,
  email,
  lastname,
  firstname,
  role,
  image,
  permission,
  deleteUser,
}) => {
  return (
    <div className="rounded shadow p-3 my-4 flex w-full justify-between">
      <div className="user-info flex w-1/4">
        <img
          src={image === (null || undefined) ? man : image}
          className="rounded-full h-11 w-11 mr-2"
          alt="user-avatar"
        />
        <div className="name-email ">
          <div className="text-lg">{`${firstname} ${lastname}`}</div>
          <p className="text-sm text-gray-500">{email}</p>
        </div>
      </div>
      <div className="user-type font-normal my-auto w-1/4 text-center">
        {role}
      </div>
      {/* {Object.entries(permission).length !== 0 ? (
        <div className="createdBy flex w-1/4 justify-center">
          <img
            src={
              permission.creator.image === null ? man : permission.creator.image
            }
            className="rounded-full h-11 w-11 mr-2"
            alt="user-avatar"
          />
          <div className="name-email ">
            <div className="text-lg">{`${permission.creator.first_name} ${permission.creator.last_name}`}</div>
            <p className="text-sm text-gray-500">{permission.creator.email}</p>
          </div>
        </div>
      ) : (
        <div className="createdBy flex w-1/4 justify-center">N/A</div>
      )} */}

      <div className="action my-auto w-1/4">
        <img
          onClick={() => deleteUser(id)}
          src={trash}
          className="m-auto cursor-pointer"
          alt=""
        />
      </div>
    </div>
  );
};
