import axios from "axios";
import { constant } from "./ApiConstants";
import {
  storageContainsToken,
  getTokenFromStorage,
  removeTokenFromStorage
} from "./ApiUtils";

// Resusable requests template
export const ApiRequest = () => {
  const config = { baseURL: constant.baseUrl };
  const instance = axios.create(config);
  return instance;
};

export const ApiRequestWithToken = () => {
  const instance = axios.create({
    baseURL: constant.baseUrl,
    headers: {
      Authorization: `Bearer ${getTokenFromStorage()}`
    }
  });

  instance.interceptors.response.use(undefined, err => {
    if (err.response) {
      if (err.response.status === 401) {
        removeTokenFromStorage();
        removeTokenFromStorage();
        window.location = "/login";
        return;
      }
    }
    return Promise.reject(err);
  });
  return instance;
};

const ApiRequestMultiPart = () => {
  const config = { baseURL: constant.baseUrl };
  if (storageContainsToken()) {
    const token = getTokenFromStorage();
    config.headers = {
      Authorization: `Bearer ${token}`,
      "content-type": `multipart/form-data; `
    };
  }
  const instance = axios.create(config);
  return instance;
};

export const fetcher = url =>
  ApiRequestWithToken()
    .get(url)
    .then(res => res.data);

// End of reusable template requests

export const login = formData => {
  return ApiRequest().post("/auth/login", formData);
};

export const forgotPassword = formData => {
  return ApiRequest().post("auth/forgot-password", formData);
};

export const getPropertyList = page =>
  ApiRequestWithToken().get(`/admin/property?sort=DESC&size=10&page=${page}`);

export const getPropertyRequest = page =>
  ApiRequestWithToken().get(
    `/admin/properties/requests?sort=DESC&size=10&page=${page}`
  );

export const deleteProperty = id =>
  ApiRequestWithToken().put(`/admin/property/${id}`);

export const getPropertiesBids = page =>
  ApiRequestWithToken().get(`admin/properties/bids`);

export const getPropertiesRequest = page =>
  ApiRequestWithToken().get(`admin/properties/requests`);

export const getPropertyDocuments = id =>
  ApiRequestWithToken().get(`admin/property/${id}/documents`);

// Internal Users
export const getAllInternalUsers = () =>
  ApiRequestWithToken().get(`admin/internal-users`);

export const deleteInternalUser = id =>
  ApiRequestWithToken().delete(`admin/internal-users/${id}`);

export const addInternalUser = data =>
  ApiRequestWithToken().post(`admin/internal-users`, data);

// News
export const getAllNews = () => ApiRequestWithToken().get(`admin/news`);

export const getSingleNews = id =>
  ApiRequestWithToken().get(`admin/news/${id}`);

export const editNews = (id, formData) =>
  ApiRequestWithToken().put(`admin/news/${id}`, formData);

export const deleteNews = id =>
  ApiRequestWithToken().delete(`admin/news/${id}`);

export const createNews = formData =>
  ApiRequestMultiPart().post(`admin/news`, formData);

// Blogs
export const getAllBlogs = () => ApiRequestWithToken().get(`admin/blogs`);

export const createBlog = formData =>
  ApiRequestMultiPart().post(`admin/blogs`, formData);

export const deleteBlog = id =>
  ApiRequestWithToken().delete(`admin/blogs/${id}`);

export const getSingleBlog = id =>
  ApiRequestWithToken().get(`admin/blogs/${id}`);

export const editBlog = (id, formData) =>
  ApiRequestWithToken().put(`admin/blogs/${id}`, formData);

// Regulations
export const getAllRegulations = () =>
  ApiRequestWithToken().get(`admin/regulations`);

export const createRegulation = formData =>
  ApiRequestWithToken().post(`admin/regulations`, formData);

export const getSingleRegulation = id =>
  ApiRequestWithToken().get(`admin/regulations/${id}`);

export const editRegulation = (id, formData) =>
  ApiRequestWithToken().put(`admin/regulations/${id}`, formData);

export const deleteRegulation = id =>
  ApiRequestWithToken().delete(`admin/regulations/${id}`);

// Subscription
export const getAllSubscription = () =>
  ApiRequestWithToken().get(`admin/subscriptions`);

export const getBidSubscription = () =>
  ApiRequestWithToken().get(`admin/subscriptions`);

export const getSingleSubscription = id =>
  ApiRequestWithToken().get(`admin/subscriptions/${id}`);

export const addSubscriptionPlan = formData =>
  ApiRequestMultiPart().post(`admin/subscriptions`, formData);

export const addBidSubscriptionPlan = formData =>
  ApiRequestWithToken().post(`admin/token-plan`, formData);

export const updateSubscriptionPlan = (id, formData) =>
  ApiRequestWithToken().put(`admin/subscriptions/${id}`, formData);

export const deleteSubscriptionPlan = id =>
  ApiRequestWithToken().delete(`admin/subscriptions/${id}`);

// Advert

export const createAdvert = formData =>
  ApiRequestMultiPart().post(`admin/adverts`, formData);

export const getAdvertHistory = () =>
  ApiRequestWithToken().get(`admin/adverts`);

export const getSingleAdvert = id =>
  ApiRequestWithToken().get(`admin/adverts/${id}/detail`);

export const updateAdvert = (id, payload) =>
  ApiRequestWithToken().put(`admin/adverts/${id}`, payload);

export const reactivateAdvert = (id, formData) =>
  ApiRequestWithToken().post(`admin/adverts/${id}`, formData);

export const getActiveAdvert = () =>
  ApiRequestWithToken().get(`admin/adverts/active`);

export const deActivateAdvert = id =>
  ApiRequestWithToken().delete(`admin/adverts/${id}`);

// Settings
export const getBoostingPlans = () =>
  ApiRequestWithToken().get(`admin/settings/boost`);

export const deleteBoostingPlan = id =>
  ApiRequestWithToken().delete(`admin/settings/boost/${id}`);

export const editBoostingPlan = (formData, id) =>
  ApiRequestWithToken().put(`admin/settings/boost/${id}`, formData);

export const addBoostingPlan = formData =>
  ApiRequestWithToken().post(`admin/settings/boost`, formData);

export const getUserLocation = () =>
  ApiRequestWithToken().get(`admin/settings/user-location`);

export const updateUserLocation = formData =>
  ApiRequestWithToken().put(`admin/settings/user-location`, formData);

export const getUserListing = () =>
  ApiRequestWithToken().get("admin/user/list?sort=DESC");

export const approvePropertyDocument = (id, document) =>
  ApiRequestWithToken().post(`/admin/property/${id}/document/approve`, {
    documentId: document,
    status: "approved"
  });

export const rejectPropertyDocument = (id, document) =>
  ApiRequestWithToken().post(`/admin/property/${id}/document/approve`, {
    documentId: document,
    status: "rejected"
  });

export const updateProfile = data =>
  ApiRequestWithToken().post("/account/update-profile", data);
