import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import FullLoader from "../../components/Loaders/FullLoader";
import { deleteBlog, getSingleBlog } from "../../utils/ApiRequests";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdUpdate } from "react-icons/md";

export const SingleBlog = () => {
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState();

  useEffect(() => {
    const getBlog = async () => {
      try {
        const { data } = await getSingleBlog(id);
        setLoading(false);
        setBlog(data.data);
        // console.log(data);
      } catch (error) {
        setLoading(false);
        console.log(error.response);
      }
    };
    getBlog();
  }, [id]);

  const handleDelete = async () => {
    try {
       await deleteBlog(id);
      toast.success("Deleted successfully");
      history.push("/legal");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  if (loading) {
    return <FullLoader />;
  }

  return (
    <>
      <div
        className="back-arrow flex cursor-pointer w-min"
        onClick={() => history.goBack()}
      >
        <AiOutlineArrowLeft style={{ margin: "auto" }} />
        <div className="font-normal ml-2 my-auto">Back</div>
      </div>

      <div className="w-max my-5">
        <h1 className="text-xl font-black">Blog</h1>
        <AccentBar width="50%" />
      </div>

      <div className="my-10">
        <div className="flex py-3 w-full">
          <div className="w-1/2 px-5">
            <div className="col-title font-black mb-2 text-xl">Image</div>
            <img src={blog?.image} style={{ width: "80%" }} alt="" />
          </div>
          <div className="w-1/2 px-5">
            <div className="col-title font-black mb-2 py-3 text-xl">
              Blog Title
            </div>
            <div>{blog?.title}</div>
            <div className="mt-8 py-3 font-black text-xl">Content</div>
            <div>{blog?.content}</div>
          </div>
        </div>

        <div className="flex my-5">
          <div className="w-1/2 px-5">
            <div
              className="w-1/2 px-10 w-min flex py-2 rounded-lg font-black text-lg cursor-pointer"
              style={{ color: "#5D1763", border: "2px solid #5D1763" }}
              onClick={() => handleDelete(id)}
            >
              <RiDeleteBin6Line style={{ margin: "auto 12px auto 0" }} /> Delete
            </div>
          </div>

          <div className="w-1/2 pr-5">
            <div
              className="px-10 w-min flex py-2 rounded-lg font-black text-lg cursor-pointer"
              style={{
                background: "#5D1763",
                color: "#ffffff"
              }}
              onClick={() => history.push(`/legal/blog/${id}/edit`)}
            >
              <MdUpdate
                style={{ margin: "auto 12px auto 0", fontSize: "26px" }}
              />
              Update
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
