import React from 'react'
import abstraction from "../../assets/images/Abstraction.png";

const AuthLayout = ({ children, title }) => {
  return (
    <div className="w-full flex h-screen bg-primary">
      <div style={{ background: `url(${abstraction})`, flex: "1", backgroundSize: 'cover' }} className="w-full h-screen ">
        <div className='mt-16'>

          <img
            src={require("../../assets/images/logo-white.svg").default}
            alt="Company Logo"
            className="mb-5 mx-auto"
          />
        </div>
      </div>

      <div style={{ flex: "3" }} className="  font-circular pt-20  bg-white items-center rounded- md:rounded-tl-3xl justify-center h-full mx-auto">

        <main className="w-2/3 mx-auto" >
          <div className="md:w-2/ md:mx-auto mx-10 md:mb-20 ">
            <p className="text-3xl  text-lightBlack md:mb-3 mb-10 text- ">{title} </p>
          </div>
          {children}
        </main >

      </div >
    </div>
  )
}

export default AuthLayout