import React from "react";

export const FormInput = ({
  label,
  placeholder,
  type,
  required,
  readOnly,
  onChange,
  name,
  value,
}) => {
  return (
    <div className="form-input block my-3">
      <label className="block"> {label}</label>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
        onChange={onChange}
        value={value}
        className="border border-gray-500 rounded px-2 py-2 w-full"
      />
    </div>
  );
};
