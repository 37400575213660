import React, { useState } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import FullLoader from "../../components/Loaders/FullLoader";
import RequestItems from "../../components/RequestItems/RequestItems";
// import useFetch from "../../hooks/useFetch";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
// import { getPropertiesRequest } from "../../utils/ApiRequests";
import useSWRFetch from "../../hooks/useSwrFetch";

const Request = () => {
  const [page, setPage] = useState(0);
  // const { response, loading } = useFetch(getPropertiesRequest, page)
  const { data: response, loading } = useSWRFetch(
    `/admin/properties/requests?sort=DESC&page=${page}&size=10`
  );
  const { totalPages } = response?.meta || {};

  // console.log(response)

  if (page < 0) {
    setPage(0);
  }

  if (page > totalPages - 1) {
    setPage(totalPages - 1);
  }

  return (
    <div>
      {loading && <FullLoader />}
      <div className="w-max">
        <h1 className="text-xl font-semibold">Requests</h1>
        <AccentBar width="60%" />
      </div>

      <div className="w-full px-5 mt-5 pt-5 pb-5 shadow-md rounded-md space-y-5">
        <div className="request-grid  p-2 text-center font-bold">
          <h1 className="text-left">Property Details</h1>
          <h1>Requested By</h1>
          <h1>Received On</h1>
          <h1>Message</h1>
          <h1>Status</h1>
        </div>

        {response?.data?.map((r, i) => (
          <RequestItems key={i} {...r} />
        ))}

        <div className="flex justify-center items-center gap-10 w-full">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <IoIosArrowBack className="text-3xl " />
            <span
              className={`${
                page === 0 ? "text-black cursor-not-allowed" : "text-upRed"
              }`}
            >
              Previous
            </span>
          </div>

          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <span
              className={`${
                page === totalPages - 1
                  ? "text-black cursor-not-allowed"
                  : "text-upRed"
              }`}
            >
              Next
            </span>
            <IoIosArrowForward className="text-3xl " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
