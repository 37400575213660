import { useEffect, useState } from "react";
import { CustomButton } from "../CustomButton/CustomButton";
import { FormInput } from "../FormInput/FormInput";
import { AiFillCloseCircle } from "react-icons/ai";
import { updateSubscriptionPlan } from "../../utils/ApiRequests";
import MiniLoader from "../Loaders/MiniLoader";
import { toast } from "react-toastify";

export const Modal = ({ closeModal, showModal, children }) => {
  return (
    <div
      className={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${
        !showModal && "hidden"
      }`}
      id="modal"
    >
      <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="modal-close-icon float-right p-5">
            <AiFillCloseCircle
              style={{ width: "30px", height: "30px" }}
              className="cursor-pointer"
              onClick={() => closeModal()}
            />
          </div>
          {children}
          {/* <div className="bg-gray-200 px-4 py-3 text-right">
            <button
              type="button"
              className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
              onClick={() => closeModal()}
            >
              Close
            </button>
            <button
              type="button"
              className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2"
              style={{ background: "#5D1763" }}
            >
              {modalActionBtnName}
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export const EditSubscriptionModal = ({
  showModal,
  closeModal,
  selectedId,
  id,
  data,
  setUpdate
}) => {
  const [plan, setPlan] = useState("");
  const [features, setFetures] = useState("");
  const [monthAmount, setMonthAmount] = useState("");
  const [yearAmount, setYearAmount] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedId === id) {
      setMonthAmount(data.monthlyprice);
      setYearAmount(data.yearlyPrice);
      setPlan(data.name);
      setFetures(data.features);
    }
  }, [
    selectedId,
    data.features,
    data.monthlyprice,
    data.name,
    data.yearlyPrice,
    id
  ]);

  const handleEdit = async e => {
    e.preventDefault();
    setLoading(true);

    // setUpdate(false);
    const formData = {
      amount_per_month: monthAmount,
      amount_per_year: yearAmount,
      attributes:
        typeof features === "string" ? features?.split(",") : data.features,
      description: data.description,
      number_of_property: data.propertyNo
    };
    try {
       await updateSubscriptionPlan(selectedId, formData);
      // console.log(res);
      setLoading(false);
      toast.success("Edit successfull");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };
  return (
    <Modal
      closeModal={closeModal}
      showModal={showModal}
      children={
        <>
          <div className="p-20">
            <div className="text-center text-2xl font-black">
              Edit Subscription
            </div>
            <form className="my-10" onSubmit={handleEdit}>
              <div className="flex my-5">
                <div className="w-1/2 pr-5">
                  <FormInput
                    label="Plan"
                    value={plan}
                    readOnly
                    onChange={e => {
                      setPlan(e.target.value);
                      //   setErrorMessage("");
                    }}
                    required={true}
                    type="text"
                  />
                </div>
                <div className="w-1/2 pr-5">
                  <FormInput
                    label="Feature"
                    value={features}
                    onChange={e => {
                      setFetures(e.target.value);
                      //   setErrorMessage("");
                    }}
                    required={true}
                    type="text"
                  />
                </div>
              </div>
              <div className="flex my-5">
                <div className="w-1/2 pr-5">
                  <FormInput
                    label="Amount/year"
                    value={yearAmount}
                    onChange={e => {
                      setYearAmount(e.target.value);
                      //   setErrorMessage("");
                    }}
                    required={true}
                    type="number"
                  />
                </div>
                <div className="w-1/2 pr-5">
                  <FormInput
                    label="Amount/month"
                    value={monthAmount}
                    onChange={e => {
                      setMonthAmount(e.target.value);
                      //   setErrorMessage("");
                    }}
                    required={true}
                    type="number"
                  />
                </div>
              </div>

              <div className="my-5">
                {loading ? (
                  <MiniLoader />
                ) : (
                  <CustomButton buttonText="Save" type="submit" />
                )}
              </div>
            </form>
          </div>
        </>
      }
    ></Modal>
  );
};
