import React from "react"
import Sidebar from "./Sidebar"
import TopBar from "./TopBar"

const GlobalLayout = ({ children }) => {
  return (
    <div className="w-screen h-screen">
      <div className="flex min-w-screen overflow-hidden h-screen">
        <Sidebar />
        <div className="min-h-screen flex-1 overflow-auto inner-layou relative">
          <TopBar />
          <div className="px-5 py-5">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default GlobalLayout
