import React from "react";

const ModalContainer = ({ children, parentOnClick }) => {
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 w-screen h-full bg-black bg-opacity-20 flex items-center justify-center z-40 px-10 py-10 "
      onClick={parentOnClick}
    >
      {children}
    </div>
  );
};
export default ModalContainer;
