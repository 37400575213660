import React, { useState } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FormInput } from "../../components/FormInput/FormInput";
import { HiCloudUpload } from "react-icons/hi";
import { addSubscriptionPlan } from "../../utils/ApiRequests";
import MiniLoader from "../../components/Loaders/MiniLoader";
import { toast } from "react-toastify";

export const AddSubscription = () => {
  const [subscriptionName, setSubscriptionName] = useState("");
  const [yearAmount, setYearAmount] = useState("");
  const [monthAmount, setMonthAmount] = useState("");
  const [attribute, setAttribute] = useState("");
  const [propertyNo, setPropertyNo] = useState("");
  const [imgFile, setImgFile] = useState();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const clearForm = () => {
    setSubscriptionName("");
    setYearAmount("");
    setAttribute("");
    setPropertyNo("");
    setPropertyNo("");
    setImgFile();
    setDescription();
    setMonthAmount();
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let bodyFormData = new FormData();

    bodyFormData.append("name", subscriptionName);
    bodyFormData.append("file", imgFile);
    bodyFormData.append("amount_per_month", monthAmount);
    bodyFormData.append("amount_per_year", yearAmount);
    bodyFormData.append("number_of_property", propertyNo);
    bodyFormData.append("attributes", attribute);
    bodyFormData.append("description", description);
    try {
      const res = await addSubscriptionPlan(bodyFormData);
      setLoading(false);
      toast.success(res.data.message);
      clearForm();
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="subscription__addWrapper">
        <div className="flex justify-between my-5">
          <div className="page-header my-auto">
            <h1 className="text-xl">Add Subscription</h1>
            <AccentBar width="100%" />
          </div>
        </div>

        <form className="p-10 shadow-lg" onSubmit={submit}>
          <div className="flex">
            <div className="w-1/3 pr-5">
              <FormInput
                label="Subscription Name"
                value={subscriptionName}
                onChange={(e) => {
                  setSubscriptionName(e.target.value);
                }}
                required={true}
                type="text"
              />
            </div>
            <div className="w-1/3 pr-5">
              <FormInput
                label="Amount/year"
                value={yearAmount}
                onChange={(e) => {
                  setYearAmount(e.target.value);
                }}
                required={true}
                type="number"
              />
            </div>
            <div className="w-1/3 pr-5">
              <FormInput
                label="Amount/month"
                value={monthAmount}
                onChange={(e) => {
                  setMonthAmount(e.target.value);
                }}
                required={true}
                type="number"
              />
            </div>
          </div>

          <div className="flex">
            <div className="w-1/3 pr-5">
              <FormInput
                label="Attribute"
                value={attribute}
                onChange={(e) => {
                  setAttribute(e.target.value);
                }}
                required={true}
                type="text"
              />
            </div>
            <div className="w-1/3 pr-5">
              <FormInput
                label="Number Of Property"
                value={propertyNo}
                onChange={(e) => {
                  setPropertyNo(e.target.value);
                }}
                required={true}
                type="number"
              />
            </div>
          </div>
          <div className="flex mt-10">
            <div className="image-input w-1/2 pr-16">
              <label className="block"> Banner Image/Gif/Video</label>

              <div className="contact-file-input">
                <label htmlFor="file-upload" className="custom-file-upload">
                  <div className="file shadow rounded w-full p-5 ">
                    {imgFile ? (
                      <img
                        src={URL.createObjectURL(imgFile)}
                        className="m-auto"
                        alt=""
                      />
                    ) : (
                      <>
                        <HiCloudUpload
                          className="mt-16 mx-auto"
                          style={{
                            height: "100px",
                            width: "100px",
                            color: "#5d1763",
                          }}
                        />
                        <p
                          className="text-center mb-16"
                          style={{ color: "#5d1763" }}
                        >
                          Select image
                        </p>
                      </>
                    )}
                  </div>
                </label>

                <input
                  id="file-upload"
                  className="hidden"
                  required
                  onChange={(e) => {
                    const [file] = e.target.files;
                    setImgFile(file);
                  }}
                  type="file"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
            <div className="content w-1/2">
              <label className="block">Description</label>
              <textarea
                className="bg-gray-100 p-3 w-full"
                name="Description"
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                placeholder="Enter description"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div className="mt-16">
            {loading ? (
              <MiniLoader />
            ) : (
              <CustomButton buttonText="Submit" type="submit" />
            )}
          </div>
        </form>
      </div>
    </>
  );
};
