import React, { useEffect, useState } from "react";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import BidsListing from "../../components/BidItems/BidsListing";
import FullLoader from "../../components/Loaders/FullLoader";
// import useFetch from "../../hooks/useFetch";
// import { getPropertiesBids } from "../../utils/ApiRequests";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import useSWRFetch from "../../hooks/useSwrFetch";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import { log } from "@craco/craco/lib/logger";

const BidsIndex = () => {
  const [page, setPage] = useState(0);
  const history = useHistory()
  const { data: response, loading } = useSWRFetch(
    // `/admin/properties/bids?sort=DESC&page=${page}&size=10`
    `/admin/bids?page=${page}&size=20`
  );
  const { totalPages } = response?.meta || {};

  // console.log(response);

  useEffect(() => {
    if (page < 1) {
      setPage(1);
    }


    if (page > totalPages - 1) {
      setPage(totalPages - 1);
    }
  }, [])
  console.log(response?.data?.data)
  return (
    <div className=" ">
      <div className="flex justify-between ">
        <div className="w-max py-3">
          <h1 className="text-xl font-semibold">Bids</h1>
          <AccentBar width="100%" />
        </div>
        <div>
          <CustomButton
            buttonText="Bid Subscriptions"
            onClick={() => history.push("/bids/subscriptions")}
          />
        </div>
      </div>

      {loading && <FullLoader />}

      <table className="w-full space-y-5 border-collaps my-5 p-5 shadow-md rounded-md space-y-5">
        <thead className="bid-gri  p-2 text-center font-bold  ">
          <td className="text-left">Property Details</td>
          <td >Owned By</td>
          {/* <td >Placed By</td> */}
          <td>Received On</td>
          <td>Bid Price</td>
          {/* <h1>Highest Bid</h1> */}
          <td>Status</td>
          <td>Action</td>
        </thead>

        <tbody className="my-5 rounded-md m-5  ">
          {response?.data?.data?.map((item, i) => (
            <BidsListing key={i} {...item} />
          ))}
        </tbody>
      </table>

      <div className="flex justify-center items-center gap-10 w-full">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            setPage(page - 1);
          }}
        >
          <IoIosArrowBack className="text-3xl " />
          <span
            className={`${page === 0 ? "text-black cursor-not-allowed" : "text-upRed"
              }`}
          >
            Previous
          </span>
        </div>

        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            setPage(page + 1);
          }}
        >
          <span
            className={`${page === totalPages - 1
              ? "text-black cursor-not-allowed"
              : "text-upRed"
              }`}
          >
            Next
          </span>
          <IoIosArrowForward className="text-3xl " />
        </div>
      </div>
    </div>
  );
};

export default BidsIndex;
