import React, { useEffect, useState } from "react";
import { getAllRegulations } from "../../utils/ApiRequests";
import { RegulationItem } from "../../components/Legal/LegalContent";
import FullLoader from "../../components/Loaders/FullLoader";

export const Regulations = () => {
  const [regulations, setRegulations] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchRegulations = async () => {
      try {
        const res = await getAllRegulations();
        // console.log(res);
        setLoading(false);
        setRegulations(res.data.data);
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error.response);
          setLoading(false);
        }
      }
    };
    fetchRegulations();
    return () => {
      abortController.abort();
    };
  }, []);

  if (loading) {
    return <FullLoader />;
  }
  return (
    <>
      {regulations?.length > 0 ? (
        regulations.map(regulation => (
          <RegulationItem regulation={regulation} />
        ))
      ) : (
        <div className="p-5 shadow">No regulations available</div>
      )}
    </>
  );
};
