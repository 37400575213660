import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { useHistory, useParams } from "react-router-dom";
import { useSWRConfig } from "swr";
import FullLoader from "../../components/Loaders/FullLoader";
import PropertyBidReceived from "../../components/PropertyItems/PropertyBidReceived";
import PropertyItemDocument from "../../components/PropertyItems/PropertyItemDocument";
import useSWRFetch from "../../hooks/useSwrFetch";

const PropertyDetails = () => {
  const history = useHistory();
  const [showDocument, setShowDocument] = useState(false);
  const { id } = useParams();
  const { data: response, loading } = useSWRFetch(`/admin/property/${id}/bids`);
  const { data: bids, loading: loadingBids } = useSWRFetch(`/admin/property-bids/${id}?page=1&size=20`);
  const { data: documents, loading: loading2 } = useSWRFetch(
    `admin/property/${id}/documents`
  );
  const { mutate } = useSWRConfig();

  const reFetchDocuments = () => mutate(`admin/property/${id}/documents`);
  // console.log(response?.data?.images[0]?.image_url);
  return (
    <div className="">
      <div
        className="flex items-center gap-2 text-xl cursor-pointer"
        onClick={() => history.goBack()}
      >
        <BsArrowLeft />
        Back
      </div>

      {(loading || loading2) && <FullLoader />}

      <h1 className="text-2xl font-bold page-title relative my-5">
        Properties Details
      </h1>

      <div className="w-full mt-10 shadow-md p-2 rounded-md flex gap-5 justify-between">
        <div className="flex gap-5">
          <img
            className="w-52 object-cover mr-3 rounded-md self-stretch"
            src={response?.data?.images[0]?.image_url}
            alt="property listing"
          />

          <div className="w-full space-y-3">
            <h1 className="truncate font-bold text-sm">
              {response?.data?.address_line_one}
            </h1>

            <div className="flex items-center text-base space-x-1">
              <IoLocationSharp className="text-upRed" />

              <h1 className="text-gray-400 truncate">{response?.data?.city}</h1>
            </div>

            <h1 className="font-bold">
              ₦{parseInt(response?.data?.price).toLocaleString("en-NG")}
            </h1>
          </div>
        </div>
        {/*
        <div className="mr-10">
          <h1 className="font-bold">Liked by</h1>

          <div className="flex gap-5 mt-2">
            <img
              src={require("../../assets/images/profile.jpg").default}
              alt="Profile"
              className="w-20 h-20 object-cover rounded-full"
            />

            <div className="space-y-1 self-center">
              <h3 className="font-semibold text-lg">Tom Cruise</h3>
              <h3 className="text-sm">test@test.com</h3>
            </div>
          </div>
        </div> */}
      </div>

      <div className="w-full px-5 py-7 shadow-md mt-10">
        <div className="flex items-center gap-10 my-5">
          <h3
            className={`font-bold text-xl cursor-pointer ${!showDocument ? "page-title" : "text-gray-400"
              }`}
            onClick={() => setShowDocument(false)}
          >
            Bids Received
          </h3>
          <h3
            className={`font-bold text-xl cursor-pointer ${showDocument ? "page-title" : "text-gray-400"
              }`}
            onClick={() => setShowDocument(true)}
          >
            Documents
          </h3>
        </div>

        {showDocument ? (
          <PropertyItemDocument
            documents={documents}
            mutate={reFetchDocuments}
            propertyId={id}
          />
        ) : (
          <PropertyBidReceived bids={bids?.data?.data} />
        )}
      </div>
    </div>
  );
};

export default PropertyDetails;
