import React from "react";
import { IoLocationSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { truncateString } from "../../utils/TruncateWord";

const RequestItems = ({ message, user, createdAt, status, property, id }) => {
  const { address_line_one, city, price, images } = property || {};
  const { first_name, last_name, email, avatar } = user || {};

  return (
    <div className="request-grid shadow-md rounded-md p-2 items-center">
      <div className="flex ">
        <img
          className="w-52 h-32 object-cover mr-3 rounded-md self-stretch"
          src={images?.length ? images[0].image_url : ""}
          alt="property listing"
        />

        <div className="w-full space-y-3">
          <h1 className="w-full font-bold">{address_line_one}</h1>

          <div className="flex items-center text-base space-x-1">
            <IoLocationSharp className="text-upRed" />

            <h1 className="text-gray-400 truncate">{city}</h1>
          </div>

          <h1 className="font-bold">
            ₦{parseInt(price).toLocaleString("en-NG")}
          </h1>
        </div>
      </div>

      <div className="flex gap-2 self-center items-center justify-center">
        <img
          src={avatar.url}
          alt="Profile"
          className="w-20 h-20 object-cover rounded-full"
        />

        <div className="space-y-2 w-3/6 truncate">
          <h3 className="font-semibold">
            {first_name} {last_name}
          </h3>
          <h3 className="text-sm">{email}</h3>
        </div>
      </div>

      <p className="text-lg text-center">
        {new Date(createdAt).toLocaleDateString()}
      </p>

      <p className="text-center truncate">{truncateString(message, 15)}</p>

      <div>
        <div>
          {status === "active" ? (
            <button className="py-2 w-full text-center text-sm font-semibold text-white bg-upGreen rounded-3xl">
              ACTIVE
            </button>
          ) : (
            <button className="py-2 w-full text-center text-sm font-semibold text-white bg-red-500 rounded-3xl">
              {status?.toUpperCase()}
            </button>
          )}
        </div>
      </div>

      {/* <a href={image_url} target="_blank" rel="noreferrer"> */}
      <Link to={`/requests/${id}`} className="w-full block">
        <img
          src={require("../../assets/images/eye.svg").default}
          alt="Eyes"
          className="cursor-pointer mx-auto"
          // onClick={() => window.}
        />
      </Link>

      {/* </a> */}
    </div>
  );
};

export default RequestItems;
