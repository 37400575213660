import React from "react";

const ProfileInputs = ({ label, handleChange, name, value }) => {
  return (
    <div className="w-full">
      <label htmlFor="" className="font-semibold text-lg">
        {label}
      </label>
      <input
        type="text"
        className="w-full outline-none border mt-1 border-gray-400 rounded-md py-1 px-3"
        onChange={handleChange}
        name={name}
        value={value}
      />
    </div>
  );
};

export default ProfileInputs;
