import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import useSWRFetch from "../../hooks/useSwrFetch";

const TopBar = () => {
  const { data } = useSWRFetch("/auth/me");

  return (
    <div className="w-full flex justify-between py-5 px-5 shadow-md sticky top-0 bg-white z-10">
      <form className="flex items-center h-10">
        <input
          type="text"
          className="rounded-l-3xl w-96 h-full outline-none border px-5 border-gray-300 border-r-0"
          placeholder="Search"
        />
        <button
          type="submit"
          className="text-center bg-upBlack h-full outline-none rounded-r-3xl text-white px-4"
        >
          <AiOutlineSearch className="text-xl font-semibold " />
        </button>
      </form>

      <div className="flex items-center gap-5">
        <img
          src={require("../../assets/images/alert.svg").default}
          className=""
          alt="alert"
        />

        <div className="flex items-center gap-5">
          <h1 className="capitalize font-bold text-lg">
            {data?.data?.first_name}
          </h1>

          <Link to="/profile">
            <img
              src={data?.data?.avatar?.url}
              alt="Profile"
              className="w-12 h-12 rounded-full"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
