import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { Blog } from "./Blog";
import { News } from "./News";
import { Regulations } from "./Regulations";

export const Legal = () => {
  const [currentTab, setCurrentTab] = useState("Blog");
  const history = useHistory();
  return (
    <div className="legal__page">
      <div className="flex justify-between my-5">
        <div className="page-header my-auto">
          <h1 className="text-xl">Legal</h1>
          <AccentBar width="100%" />
        </div>

        {currentTab === "Blog" && (
          <CustomButton
            buttonText="Add Blog"
            onClick={() => history.push("/legal/add-blog")}
          />
        )}

        {currentTab === "News" && (
          <CustomButton
            buttonText="Add News"
            onClick={() => history.push("/legal/add-news")}
          />
        )}
        {currentTab === "Regulations" && (
          <CustomButton
            buttonText="Add Regulations"
            onClick={() => history.push("/legal/add-regulation")}
          />
        )}
      </div>

      <div className="legal-info p-5 shadow rounded">
        <div className="legal-tabs flex mb-10">
          <div className="tab-item font-bold text-lg mr-5">
            <h1
              className="cursor-pointer"
              onClick={() => setCurrentTab("Blog")}
            >
              Blog
            </h1>
            {currentTab === "Blog" && <AccentBar width="60%" />}
          </div>
          <div className="tab-item font-bold text-lg mr-5">
            <h1
              className="cursor-pointer"
              onClick={() => setCurrentTab("News")}
            >
              News
            </h1>
            {currentTab === "News" && <AccentBar width="60%" />}
          </div>
          <div className="tab-item font-bold text-lg mr-5">
            <h1
              className="cursor-pointer"
              onClick={() => setCurrentTab("Regulations")}
            >
              Regulations
            </h1>
            {currentTab === "Regulations" && <AccentBar width="60%" />}
          </div>
        </div>

        <div className="legal-lists">
          {currentTab === "Blog" && <Blog />}
          {currentTab === "News" && <News />}
          {currentTab === "Regulations" && <Regulations />}
        </div>
      </div>
    </div>
  );
};
