import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { useHistory, useParams } from "react-router-dom";
import { AccentBar } from "../../components/AccentBar/AccentBar";
import FullLoader from "../../components/Loaders/FullLoader";
import useSWRFetch from "../../hooks/useSwrFetch";

const SingleRequest = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data, loading } = useSWRFetch(`/admin/properties/requests/${id}`);

  // console.log(data);

  return (
    <div className="px-5 py-5">
      {loading && <FullLoader />}

      <div
        className="flex items-center gap-2 text-xl cursor-pointer mb-5"
        onClick={() => history.goBack()}
      >
        <BsArrowLeft />
        Back
      </div>

      <div className="w-max">
        <h1 className="text-xl font-semibold">Requests</h1>
        <AccentBar width="90%" />
      </div>

      <div className="single-request-grid mt-10">
        <div className="space-y-4">
          <img
            src={data?.data?.property?.images[0]?.image_url}
            alt={data?.data?.property?.address_line_one}
            className="w-full h-36 object-cover rounded-md"
          />

          <h1 className="truncate font-bold text-sm w-64">
            {data?.data?.property?.address_line_one}
          </h1>

          <div className="flex items-center text-base space-x-1">
            <IoLocationSharp className="text-upRed" />

            <h1 className="text-gray-600 truncate">
              {data?.data?.property?.city}
            </h1>
          </div>

          <div className="flex items-center gap-20">
            <h4 className="font-bold">
              ₦{parseInt(data?.data?.property?.price).toLocaleString("en-NG")}
            </h4>

            <div>
              <button
                className={`w-full text-white px-5 text-center py-1 text-sm font-semibold rounded-xl ${
                  data?.data?.status === "active"
                    ? "bg-green-400"
                    : "bg-gray-400"
                }`}
              >
                {data?.data?.status === "active"
                  ? "ACTIVE"
                  : data?.data?.status?.toUpperCase()}
              </button>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex w-full justify-between gap-10">
            <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold">Requested By</h1>

              <div className="flex items-center gap-4">
                <img
                  src={data?.data?.user?.avatar?.url}
                  alt={data?.data?.user?.avatar?.email}
                  className="h-32 w-32 rounded-full object-cover"
                />

                <div className="space-y-1 font-WorkSans">
                  <h1 className="font-semibold">
                    {data?.data?.user?.first_name} {data?.data?.user?.last_name}
                  </h1>
                  <h1 className="text-sm">{data?.data?.user?.email}</h1>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h1 className="font-WorkSans font-semibold">Received On</h1>

              <h1>{new Date(data?.data?.createdAt).toLocaleDateString()}</h1>
            </div>
          </div>

          <div className="py-10 space-y-3">
            <h1 className="font-WorkSans font-semibold">Message</h1>
            <h1 className="font-WorkSans">{data?.data?.message}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleRequest;
