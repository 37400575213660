import React from "react";
import { Link } from "react-router-dom";

const UserItemListing = ({
  email,
  avatar,
  first_name,
  last_name,
  role,
  properties,
  status,
  id
}) => {
  return (
    <div className="user-grid shadow-md rounded-md p-2 items-center">
      <Link className="flex gap-3 self-center items-center" to={`/users/${id}`}>
        <img
          src={avatar?.url}
          alt="Profile"
          className="w-20 h-20 object-cover rounded-full"
        />

        <div className="space-y-2">
          <h3 className="font-semibold">
            {first_name} {last_name}
          </h3>
          <h3 className="text-sm">{email}</h3>
        </div>
      </Link>

      <p className="text-lg text-center capitalize">{role?.name}</p>

      <p className="text-center">{properties?.length} Properties</p>
      <p className="text-center">Nil</p>

      <div>
        {status === "active" ? (
          <button className="py-2 w-full text-center text-sm font-semibold text-white bg-upGreen rounded-3xl">
            ACTIVE
          </button>
        ) : (
          <button className="py-2 w-full text-center text-sm font-semibold text-white bg-red-500 rounded-3xl">
            {status?.toUpperCase()}
          </button>
        )}
      </div>
    </div>
  );
};

export default UserItemListing;
